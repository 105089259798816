import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { Fragment, useEffect, useState } from 'react';
import { ExternalSeedingRace } from '../../model/ExternalSeedingRace';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Link, Select } from '@material-ui/core';
import ExternalSeedingRacesAddRace from './ExternalSeedingRacesAddRace';
import uuidv4 from 'react-uuid';
import { ApiBackend } from '../../providers/apibackend';
import { vasaloppetDateGetterFormatter } from '../../utilities/date';
import ExternalSeedingRaceUploadResult from './ExternalSeedingRaceUploadResults';
import ExternalSeedingRaceShowResults from './ExternalSeedingRaceShowResults';


const ExternalSeedingRaces = ()  => {

    const thisYear = new Date().getFullYear();
    const [year, setYear] = useState(thisYear);
    const [seedingRaces, setSeedingRaces] = useState(null as ExternalSeedingRace[]);
    const [editRace, setEditRace] = useState(null as ExternalSeedingRace);
    const [deleteRace, setDeleteRace] = useState(null as ExternalSeedingRace);
    const [uploadResults, setUploadResults] = useState(null as ExternalSeedingRace);
    const [showResults, setShowResults] = useState(null as ExternalSeedingRace);

    const updateRaces = () => {
        let back = new ApiBackend();
        back.listExternalSeedingRaces(year).then((data) => {
            setSeedingRaces(data);
        });
    }

    useEffect(() => {
       updateRaces();
    },[]);

    useEffect(() => {
        updateRaces();
    },[year]);

    const years = [];
    for (let i = thisYear; i > 2018; i--)
    {
        years.push(i);
    }


    const addRace = () => {
        let nr = new ExternalSeedingRace();
        nr.date = new Date();
        nr.year = nr.date.getFullYear();
        nr.raceDiscipline="ski";
        nr.raceParticipationType="in_arena";
        nr.raceType="individual";
        setEditRace(nr);
    }

    const openEditRace = (race: ExternalSeedingRace) => {
        setEditRace(race);
    }

    
    const handleDelete = () => {
        let back = new ApiBackend();
        back.deleteExternalSeedingRace(deleteRace.year, deleteRace.id).then(() => {
            setDeleteRace(null);
            updateRaces();
        });
    }
    const renderDeleteConfirmationDialog = () => {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort {deleteRace.name}?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={() => {setDeleteRace(null)}} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={() => {handleDelete()}} color="primary">
                    Ja, ta bort
          </Button>
            </DialogActions>
        </Dialog>;
    }
    const renderUploadResults = () => {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="lg"
            
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Ladda upp resultat till {uploadResults.name}?</DialogTitle>
            <DialogContent><ExternalSeedingRaceUploadResult externalSeedingRace={uploadResults} close={() => {
                setUploadResults(null);
                updateRaces();
            }}/></DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={() => {setUploadResults(null)}} color="primary">
                    Stäng
          </Button>
            </DialogActions>
        </Dialog>;
    }

    const renderShowResults = () => {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="lg"
            fullWidth
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Resultat för {showResults.name}</DialogTitle>
            <DialogContent><ExternalSeedingRaceShowResults externalSeedingRace={showResults} close={() => {
                setShowResults(null);
            }}/></DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={() => {setShowResults(null)}} color="primary">
                    Stäng
          </Button>
            </DialogActions>
        </Dialog>;
    }


    const renderAddRace = () => {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">{editRace.id ? "Editera lopp" : "Lägg till lopp"}</DialogTitle>
            <DialogContent>
                <ExternalSeedingRacesAddRace externalSeedingRace={editRace} onChanged={(r) => {setEditRace({...r})}}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={() => {setEditRace(null);}} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" color="primary" onClick={() => {
                    let back = new ApiBackend();
                    if (!editRace.id)
                    {
                        editRace.id=uuidv4();
                    }
                    back.addOrUpdateExternalSeedingRace(editRace).then(x=>{
                        setEditRace(null);
                        updateRaces();
                    });
                }}>                    
                    Spara
          </Button>
            </DialogActions>
        </Dialog>;
    }


return <Fragment><Grid container spacing={2}>
    <Grid item xs={12}>
        <Card>

            <CardHeader title="Externa seedninglopp" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl>
                            <InputLabel shrink>År</InputLabel>
                            <Select native value={year} onChange={(ev) => {
                                setYear(+ev.target.value);
                            }}>
                                {years.map((y)=> {
                                    return <option value={y}>{y}</option>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained"  onClick={() => {addRace();}}>Lägg till lopp</Button>
                    </Grid>
                    <Grid item xs={5}>
                    </Grid>

                    <Grid item xs={12}>

                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Datum</TableCell>
                                        <TableCell>Namn</TableCell>
                                        <TableCell>Resultat</TableCell>
                                        <TableCell style={{ width: 70 }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {seedingRaces && seedingRaces.map(race => {
                                        return <TableRow key={race.eventKey}>
                                            <TableCell>{vasaloppetDateGetterFormatter(race.date)}</TableCell>
                                            <TableCell>{race.name}</TableCell>
                                            <TableCell>
                                                {race.numResults > 0 && <>
                                                    <Link color="inherit" variant="body2" href="#" onClick={() =>{setShowResults(race);}} >Visa {race.numResults} resultat</Link> | &nbsp;
                                                    </>
                                                }
                                                <Link color="inherit" variant="body2" href="#" onClick={() =>{setUploadResults(race);}} >Ladda upp resultat</Link>
                                                </TableCell>
                                            <TableCell>
                                                <EditIcon style={{ cursor: 'pointer' }} onClick={() => { openEditRace(race);  } } />
                                                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => { setDeleteRace(race); } } /></TableCell>
                                        </TableRow>;
                                    })}
                                </TableBody>
                            </Table></TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
</Grid>
{editRace && renderAddRace()}
{deleteRace && renderDeleteConfirmationDialog()}
{uploadResults && renderUploadResults()}
{showResults && renderShowResults()}
</Fragment>
}
export default ExternalSeedingRaces;
