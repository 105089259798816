
import { Button, ButtonGroup, Card, CardContent, CardHeader, Grid, IconButton, TextField, WithStyles } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React from "react";

import { Entry } from "../../../model/Entry";
import { Person } from "../../../model/Person";
import { Countries, ICountryCode } from "../../../providers/countries";
import FormReadonlyField from "../../Common/FormReadOnlyField";
import { FormikProps } from "formik";
import AdminButton from "../../Common/AdminButton/AdminButton";

interface IProps extends FormikProps<Entry> {
    person: Person;
    expanded?: boolean;
    onRequestChangePerson: () => void;
    onShowPerson: () => void;
}

interface IState {
    expanded: boolean;
}

class EntryPersonCard extends React.Component<WithStyles & IProps, IState> {
    private readonly allCountries: ICountryCode[];

    constructor(props: WithStyles & IProps) {
        super(props);
        this.state = { expanded: this.props.expanded || false };
        this.allCountries = Countries.getCountries();
    }

    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded })
    }

    render() {
        const { classes, dirty, onRequestChangePerson, onShowPerson } = this.props;
        const { expanded } = this.state;

        const countryValueFormatter = (abbr: string): string => {
            const match = this.allCountries.find(x => x.abbr === abbr);
            return match?.name || abbr;
        }

        return (
            <Card>
                <CardHeader
                    action={<>
                        <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                            <AdminButton disabled={dirty} onClick={onRequestChangePerson}>Byt person</AdminButton>
                            <Button onClick={onShowPerson}>Visa person</Button>
                        </ButtonGroup>
                        <IconButton onClick={this.handleExpandClick}>
                            {expanded ? (
                                <KeyboardArrowUp />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    </>

                    }
                    title="Uppgifter på anmälan"
                />
                {expanded && (
                    <CardContent>
                        <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                            <Grid item xs={12}>
                                <TextField
                                    id="vasaId"
                                    disabled
                                    style={{ flex: 1, margin: '0 3px' }}
                                    type="text"
                                    label="Vasa ID"
                                    InputLabelProps={{ shrink: true }}
                                    value={this.props.person.vasaId}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormReadonlyField {...this.props}
                                    propName="firstName"
                                    label="Förnamn"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormReadonlyField {...this.props}
                                    propName="lastName"
                                    label="Efternamn"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormReadonlyField {...this.props}
                                    propName="email"
                                    label="E-post"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormReadonlyField {...this.props}
                                    propName="phone"
                                    label="Telefon"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormReadonlyField {...this.props}
                                    valueFormatter={countryValueFormatter}
                                    propName="nationality"
                                    label="Nationalitet"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormReadonlyField {...this.props}
                                    propName="address"
                                    label="Adress"
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <FormReadonlyField {...this.props}
                                    propName="postalCode"
                                    label="Postnummer"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormReadonlyField {...this.props}
                                    propName="city"
                                    label="Ort"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormReadonlyField {...this.props}
                                    propName="county"
                                    label="Län"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormReadonlyField {...this.props}
                                    valueFormatter={countryValueFormatter}
                                    propName="country"
                                    label="Land"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                )}
            </Card>
        )
    }
}

export default EntryPersonCard;
