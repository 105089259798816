/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { ChangeEvent, Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, withStyles, Button, createStyles, Grid, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, TextField, Typography, Input, InputLabel, Select, MenuItem, Chip } from '@material-ui/core';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { PointOfSale } from '../../model/PointOfSale';

interface State {
    loading : boolean;
    addPlace: boolean;
    posName: string;
    posId: string;
    posAccount: string;
    pos: PointOfSale[];
    confirmDelete : PointOfSale;
}

class ManagePaymentChannels extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;
    constructor(props : RouteComponentProps)
    {
        super(props);
        this.state = {loading: true, pos: null, posName: null, posId:null, addPlace: false, confirmDelete: null, posAccount:null};
    }

    componentDidMount() {
        this.refresh();

    }

    refresh = () => {
        let back = new ApiBackend();
        back.listPointOfSales().then(res => {
            this.setState({pos: res, loading: false});
        })
    }

    savePos = async () => {
        let back = new ApiBackend();
        this.setState({loading: true});
        await back.addOrUpdatePointOfSales({
            id: this.state.posId,
            name: this.state.posName,
            account: this.state.posAccount
        }).then((res) => {
            this.setState({loading:false, addPlace: false, posName: null, posId: null, posAccount: null});
            if (res == null)
            {
                alert("Kunde inte spara!");
            }
            else 
            {
                this.refresh();
            }
        })
    }

    render() {
        let { classes } = this.props as any;

        return  <Card>
        <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{display:'inline'}}>Betalsätt</Typography></Fragment>} />
        <CardContent>
        {this.state.loading &&
            <LinearProgress color="secondary"/>
            }                        
            <Grid container spacing={2}>
            {this.state && !this.state.addPlace &&
                <Fragment>
                {this.state.pos && this.state.pos.map((pos) => {
                    let p = pos;
                    return <Fragment key={pos.id}>
                        <Grid item xs={12}>
                        <EditIcon style={{cursor: 'pointer'}} onClick={() => {this.setState({addPlace: true, posName: p.name, posId: p.id, posAccount: p.account});}} />
                        <DeleteIcon style={{cursor: 'pointer'}}
                        onClick={() => {
                            this.setState({confirmDelete: pos});}}/>
                            {pos.name}</Grid>
                    </Fragment>;
                })}
                <Button variant="contained" onClick={() => {this.setState({addPlace: true, posId: null, posName:'', posAccount: ''})}}>Lägg till</Button>       
                </Fragment>
            }
            {this.state.addPlace && 
                <Fragment>
                    <Grid item xs={12}><TextField placeholder="Namn" value={this.state.posName} onChange={(ev : React.ChangeEvent<HTMLInputElement>)=> {this.setState({posName:ev.target.value});}}/></Grid>
                    <Grid item xs={12}><TextField placeholder="Konto" value={this.state.posAccount} onChange={(ev : React.ChangeEvent<HTMLInputElement>)=> {this.setState({posAccount :ev.target.value});}}/></Grid>
                    <Grid item xs={12}><Button variant="contained" onClick={this.savePos}>Spara</Button><Button variant="contained" onClick={() => {this.setState({
                        addPlace: false,
                        posId: null,
                        posName: null,
                        posAccount: null
                    })}}>Avbryt</Button></Grid>
                </Fragment>
            }
            </Grid>
            {this.state.confirmDelete && this.renderConfirmationDialog()}
        </CardContent>
        </Card>;
    }

    renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort {this.state.confirmDelete.name}?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
          </Button>
            </DialogActions>
        </Dialog>;
    }
    handleDeleteCancel = () => {
        this.setState({ confirmDelete: null });
    }

    handleDeleteOk = () => {
        this.setState({loading:true});
        let back = new ApiBackend();
        back.deletePointOfSale(this.state.confirmDelete).then((res) => {
            this.setState({loading:false, confirmDelete: null}, () => {
                this.refresh();
            });
        })
    }
}


const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withRouter(withStyles(useStyles)(ManagePaymentChannels));