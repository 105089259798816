import React, { useEffect, useRef, useState } from "react";
import { Entry } from "../../../../model/Entry";
import { ApiBackend } from "../../../../providers/apibackend";
import { Product } from "../../../../model/Product";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import withToaster, { IToasterContext } from "../../../Common/Toaster";

interface IProps {
    entry: Entry;
    entryProduct: Product;
    onComplete: () => void;
    onAbort: () => void;
}

interface IMessage {
    message: string;
    severity: "warning" | "error"
}

const SplitTeamDialog = ({ entry, entryProduct, onComplete, onAbort, showToast }: IProps & IToasterContext) => {
    const [warnings, setWarnings] = useState(null as IMessage[]);
    const [saving, setSaving] = useState(false);

    const didMount = useRef<boolean>(false);
    const initialized = useRef<boolean>(false);
    const backend = new ApiBackend();

    useEffect(() => {
        didMount.current = true;

        init();

        // unmount
        return () => {didMount.current = false};
    }, []);

    const init = () => {
        const warnings: IMessage[] = [];

        const variant = entryProduct?.variants?.find(x => x.Id === entry.variantId);
        if (variant?.productVariantRaceData?.type !== "team") {
            warnings.push({
                severity: "error",
                message: "Ursprungsloppet är inte ett laglopp. Det går inte att dela upp deltagare."
            });
        } else {
            const validTeamMembers = entry.relayMembers?.filter(x => x.firstName && x.lastName);
            if (!validTeamMembers || validTeamMembers.length === 0) {
                warnings.push({
                    severity: "warning",
                    message: "Det måste finnas minst en giltig lagmedlem för att dela upp laget."
                });
            }

            const targetVariant = entryProduct?.variants?.find(x => x.Id !== variant.Id && x.productVariantRaceData?.type === "individual");
            if (!targetVariant) {
                warnings.push({
                    severity: "warning",
                    message: "Det finns ingen individuell motsvarande variant konfigurerad för loppet. Se över loppkonfigurationen."
                });
            }

            if (!!targetVariant) {
                const targetPriceGroup = targetVariant.priceGroups?.find(x => x.Name.toLowerCase() === "friplats");
                if (!targetPriceGroup) {
                    warnings.push({
                        severity: "warning",
                        message: "Det finns ingen prisgrupp med namn 'Friplats' konfigurerad för det individuella loppet. Se över loppkonfigurationen."
                    });
                }

                const targetStartGroup = entryProduct?.startGroups?.find(x => x.participantCategory === targetVariant.Id);
                if (!targetStartGroup) {
                    warnings.push({
                        severity: "warning",
                        message: "Det finns ingen startgrupp konfigurerad med deltagarkategori för det individuella loppet. Se över loppkonfigurationen."
                    });
                }
            }
        }

        setWarnings(warnings);
        initialized.current = true;
    };

    const handleSave = async () => {
        try {
            setSaving(true);
            const splitResponse = await backend.splitTeamEntry(entry.id);
            if (!splitResponse) {
                throw new Error("Error splittnig team");
            }
            onComplete();
        } catch (e) {
            showToast("Någonting gick fel vid delning av lag", "error");
        } finally {
            setSaving(false);
        }
    };

    const renderContent = () => {
        if (!initialized.current) {
            return null;
        }

        if (!warnings || warnings.length === 0) {
            return (
                <Alert severity="info">
                    <strong>Laget uppfyller samtliga villkor för att delas upp till individuella rader.</strong>
                </Alert>
            );
        }

        return warnings.map((x, idx) => {
            return (
                <Alert key={idx} severity={x.severity}>
                    <strong>
                        {x.message}
                    </strong>
                </Alert>
            )
        });
    };

    const render = () => {
        const saveDisabled = !initialized.current || (warnings && warnings.length > 0) || saving;

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="lg"
                aria-labelledby="split-team-dialog-title"
                open={true}>
                <DialogTitle id="split-team-dialog-title">
                    Dela upp lag till individuella starter
                </DialogTitle>
                <DialogContent dividers>
                    {saving &&
                        <LinearProgress color="secondary" />
                    }
                    {renderContent()}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                        disabled={saveDisabled}
                        onClick={handleSave}>
                        Dela upp
                    </Button>
                    <Button variant="contained" style={{ marginLeft: 5 }}
                        disabled={saving}
                        onClick={onAbort}>
                        Avbryt
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };

    return render();
};

export default withToaster(SplitTeamDialog);
