import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import uuidv4 from "react-uuid";
import { ProductBundle } from "../../model/ProductBundle";
import { ApiBackend } from "../../providers/apibackend";
import EditBundleFormContent from "./EditBundleFormContent";

interface IProps {
    onAbort: () => void;
}

const CreateBundleDialog = (props: IProps & WithStyles & RouteComponentProps) => {
    const [bundle, setBundle] = useState(null as ProductBundle);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();
    const formId = `edit-catalogue-form-${uuidv4()}`;

    // Run on load
    useEffect(() => {
        didMount.current = true;

        const newBundle = new ProductBundle();
        newBundle.groups = [];
        newBundle.id = uuidv4();
        setBundle(newBundle);

        // unmount
        return () => {didMount.current = false};
    }, []);

    const save = async (values: ProductBundle): Promise<ProductBundle> => {
        return await backend.addOrUpdateProductBundle(values);
    };

    const handleOnSaveSuccessful = () => {
        props.history.push(`manage/${bundle.id}`);
    };

    const render = () => {
        const { classes, onAbort } = props;

        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="edit-bundle-title"
            open={true}
        >
            <DialogTitle id="edit-bundle-title">Lägg till produktbundling</DialogTitle>

            {bundle &&
                <Formik
                    initialValues={bundle}
                    onReset={(values): void => {
                        setBundle(values);
                        onAbort();
                    }}
                    onSubmit={async (values, { resetForm }) => {
                        const result = await save(values);

                        if (result) {
                            resetForm({ values: result });
                            handleOnSaveSuccessful();
                            // onSave(result);
                        }
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;

                        return (<>
                            <DialogContent dividers>
                                <form id={formId} autoComplete="off" onSubmit={handleSubmit}>
                                    <EditBundleFormContent {...formik} classes={classes} mode="create" />
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button form={formId} type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">
                                    Spara
                                </Button>
                                <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={handleReset}>
                                    {dirty ? "Avbryt" : "Stäng"}
                                </Button>
                            </DialogActions>
                        </>);
                    }}
                </Formik>
            }

        </Dialog>;
    }

    return render();
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(CreateBundleDialog));
