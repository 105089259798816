/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Theme, WithStyles, createStyles, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TableHead from '@material-ui/core/TableHead/TableHead';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { Fragment } from 'react';
import { CustomField } from '../../model/CustomField';
import { Product } from '../../model/Product';
import { ProductVariant } from '../../model/ProductVariant';
import { ProductVariantAgeCategory } from '../../model/ProductVariantAgeCategory';
import { ProductOccation } from '../../model/ProductVariantOccation';
import { ProductVariantPriceGroup } from '../../model/ProductVariantPriceGroup';
import { ApiBackend } from '../../providers/apibackend';
import EditAgeCategories from './EditAgeCategories';
import EditPriceGroups from './EditPriceGroups';
import ManageRace from './ManageRace';
import ProductVariantEntryFieldsCard from './EntryFields/ProductVariantEntryFieldsCard';

interface Props {
    product: Product;
    variantsChanged: () => void;
    editLocale: string;
    haveChanges: boolean;
}
interface State {
    loading: boolean;
    haveLocalChanges: boolean;
    showDeleteConfirmation: boolean;
    deleteConfirmation: ProductVariant;
    addVariant: boolean;
    addEditVariant: ProductVariant;
    addVariantOccation: boolean;
    addEditVariantOccation: ProductOccation;
    customFields: CustomField[];
}

/** 
 * Lists variants for product
 */
class ProductVariantsRace extends React.Component<WithStyles & Props, State> {

    state: State = { customFields: null as CustomField[], addEditVariantOccation: null as ProductOccation, addVariantOccation: false, loading: false, haveLocalChanges: false, showDeleteConfirmation: false, addEditVariant: null as ProductVariant, deleteConfirmation: null as ProductVariant, addVariant: false };

    componentDidMount(): void {
        let back = new ApiBackend();
        back.listCustomFields().then((fields) => {
            this.setState({ customFields: fields });
        })
    }

    private editVariant = (variant: ProductVariant) => () => {
        this.setState({ addEditVariant: variant, addVariant: false });
    };

    private removeVariant = (idx: number) => () => {
        this.setState({ deleteConfirmation: this.props.product.variants[idx], showDeleteConfirmation: true });
    };

    private addVariant = () => {
        let newVar = ProductVariant.createNew();
        newVar.productVariantTiming = { partner: 'est', eventId: '', raceId: '', timingId: '' };
        newVar.productVariantRaceData = { type: 'individual', entryFields: [], numberOfTeamMembers: 1, gender: '', minAge: 0, maxAge: 200, entryOpenDate: new Date(), entryCloseDate: new Date(), eventKey: '', discipline: "ski", participationType: 'in_arena', vlDistance: '90', isSSFCompetition: false, entryFieldDefinitions: [] };
        this.setState({ addVariant: true, addEditVariant: newVar });
    };

    private changeEditField = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.changeEditVal(prop, ev.target.value);
    };

    private changeEditFieldCheck = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.changeEditVal(prop, ev.target.checked);
    };

    private changeEditVal = (prop: string, value: any) => {
        let o = this.state.addEditVariant as any;

        if (prop.startsWith("timing.")) {
            let t = this.state.addEditVariant.productVariantTiming as any;
            t[prop.replace("timing.", "")] = value;
            o.productVariantTiming = t;
        }
        else if (prop.startsWith("racedata.")) {
            if (prop === "racedata.resultPrincipal" && value === "-1") {
                value = null;
            }

            let t = this.state.addEditVariant.productVariantRaceData as any;
            t[prop.replace("racedata.", "")] = value;
            o.productVariantRaceData = t;
        }
        else {
            o[prop] = value;
        }
        this.setState({ addEditVariant: o });
    };

    private cancelAddEditVariant = () => {
        this.setState({ addVariant: false, addEditVariant: null, haveLocalChanges: false });
    };

    private create = () => {
        var e = this.props.product;
        if (!e.variants) {
            e.variants = [];
        };
        if (this.state.addVariant) {
            e.variants.push(this.state.addEditVariant);
        }
        this.setState({ addVariant: false, addEditVariant: null, haveLocalChanges: true });
        this.props.variantsChanged();
    };

    render() {
        let { classes } = this.props;
        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title="Deltagarkategori" />
                    <CardContent>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Namn</StyledTableCell>
                                        <StyledTableCell>Beskrivning</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.product.variants && this.props.product.variants.map((variant, idx) => {
                                        return <TableRow key={idx}>
                                            <StyledTableCell>{this.props.editLocale == "EN" ? variant.Name_En : variant.Name}</StyledTableCell>
                                            <StyledTableCell>{this.props.editLocale == "EN" ? variant.Description_En : variant.Description}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <ExpandLess style={{ cursor: 'pointer' }} onClick={this.moveVariantUp(idx)} />
                                                <ExpandMore style={{ cursor: 'pointer' }} onClick={this.moveVariantDown(idx)} />
                                                <EditIcon onClick={this.editVariant(variant)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                <DeleteIcon onClick={this.removeVariant(idx)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                            </StyledTableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table></TableContainer>

                        {this.state && this.state.addEditVariant &&
                            this.renderEditVariantDialog()
                        }
                    </CardContent>
                    <CardActions>
                        <Button size="small" color="secondary" onClick={this.addVariant}>Lägg till kategori</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
            {this.state && this.state.showDeleteConfirmation &&
                this.renderConfirmationDialog()
            }
        </Fragment>

    }

    private handlePriceGroupsChange = (priceGroups: ProductVariantPriceGroup[]) => {
        const o = this.state.addEditVariant;
        o.priceGroups = priceGroups;
        this.setState({ addEditVariant: o, haveLocalChanges: true });
    };

    private handleAgeCategoriesChange = (ageCategories: ProductVariantAgeCategory[]) => {
        const o = this.state.addEditVariant;
        o.ageCategories = ageCategories;
        this.setState({ addEditVariant: o, haveLocalChanges: true });
    };

    private moveVariantUp = (idx: number) => () => {
        if (idx > 0) {
            let o = this.props.product;;
            var rem = o.variants.splice(idx, 1);
            o.variants.splice(idx - 1, 0, rem[0]);

            this.props.variantsChanged();
        }
    };

    private moveVariantDown = (idx: number) => () => {
        let o = this.props.product;
        if (idx < o.variants.length - 1) {
            var rem = o.variants.splice(idx, 1);
            o.variants.splice(idx + 1, 0, rem[0]);

            this.props.variantsChanged();
        }
    };

    private renderEditVariantDialog() {
        let { classes } = this.props;
        return <Dialog
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">{this.state.addVariant ? "Lägg till Kategori" : "Editera Kategori"}</DialogTitle>
            <DialogContent dividers>
                <Grid container className={classes.root} spacing={2} style={{ width: '100%', padding: 3 }}>
                    <Grid item xs={9}>
                        <TextField value={this.props.editLocale == "EN" ? this.state.addEditVariant.Name_En : this.state.addEditVariant.Name} label="Namn" onChange={this.changeEditField(this.props.editLocale == "EN" ? 'Name_En' : 'Name')} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField value={this.state.addEditVariant.productVariantRaceData.eventKey} label="SportsProEventKey" onChange={this.changeEditField('racedata.eventKey')} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={this.props.editLocale == "EN" ? this.state.addEditVariant.Description_En : this.state.addEditVariant.Description} label="Beskrivning" onChange={this.changeEditField(this.props.editLocale == "EN" ? 'Description_En' : 'Description')} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth className={classes.formControl} style={{ margin: 0 }}>
                            <InputLabel shrink>
                                Typ av lopp
                            </InputLabel>
                            <Select native value={this.state.addEditVariant.productVariantRaceData.type} onChange={this.changeEditField('racedata.type')} label="Typ av lopp">
                                {ApiBackend.raceTypes.map((item, idx) => {
                                    return <option key={idx} value={item.code}>{item.name}</option>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {this.state.addEditVariant.productVariantRaceData.type != 'individual' &&
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.addEditVariant.productVariantRaceData.numberOfTeamMembers} label="Antal deltagare i lag" onChange={this.changeEditField("racedata.numberOfTeamMembers")} />
                        </Grid>
                    }
                    <Grid item xs={3}>
                        <Box display="flex">
                            <TextField type="number" value={this.state.addEditVariant.productVariantRaceData.minAge} label="Minsta ålder" onChange={this.changeEditField("racedata.minAge")} />
                            <TextField type="number" value={this.state.addEditVariant.productVariantRaceData.maxAge} label="Max ålder" onChange={this.changeEditField("racedata.maxAge")} />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>Tillåtna kön i loppet</InputLabel>
                            <Select fullWidth value={(!this.state.addEditVariant.productVariantRaceData.gender || this.state.addEditVariant.productVariantRaceData.gender == "") ? "all" : this.state.addEditVariant.productVariantRaceData.gender} onChange={this.changeEditField("racedata.gender")}>
                                <MenuItem value="all">Alla</MenuItem>
                                <MenuItem value="w">Kvinnor</MenuItem>
                                <MenuItem value="m">Män</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {this.state.addEditVariant.productVariantRaceData.type == 'individual' &&
                        <Grid item xs={3}>
                        </Grid>
                    }

                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>Disciplin</InputLabel>
                            <Select fullWidth value={this.state.addEditVariant.productVariantRaceData.discipline} onChange={this.changeEditField("racedata.discipline")}>
                                <MenuItem value="ski">Skidåkning</MenuItem>
                                <MenuItem value="bike">Cykling</MenuItem>
                                <MenuItem value="run">Löpning</MenuItem>
                                <MenuItem value="event">Evenemang</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>Typ av lopp</InputLabel>
                            <Select fullWidth value={this.state.addEditVariant.productVariantRaceData.participationType} onChange={this.changeEditField("racedata.participationType")}>
                                <MenuItem value="in_arena">Fysiskt</MenuItem>
                                <MenuItem value="virtual">Virtuell</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>Vasaloppsdistans</InputLabel>
                            <Select fullWidth value={this.state.addEditVariant.productVariantRaceData.vlDistance} onChange={this.changeEditField("racedata.vlDistance")}>
                                <MenuItem value="10">10km</MenuItem>
                                <MenuItem value="30">30km</MenuItem>
                                <MenuItem value="45">45km</MenuItem>
                                <MenuItem value="90">90km</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel label="Tävlingslopp hos SSF?"
                            control={<Checkbox checked={this.state.addEditVariant.productVariantRaceData.isSSFCompetition} onChange={this.changeEditFieldCheck("racedata.isSSFCompetition")} />} />
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>Resultatprincip</InputLabel>
                            <Select fullWidth
                                value={this.state.addEditVariant.productVariantRaceData.resultPrincipal ?? "-1"}
                                onChange={this.changeEditField("racedata.resultPrincipal")}>
                                <MenuItem value="-1">Inte angivet</MenuItem>
                                <MenuItem value="RankingAndTime">Tid och placering</MenuItem>
                                <MenuItem value="TimeOnly">Enbart tid</MenuItem>
                                <MenuItem value="NoTiming">Bokstavsordning utan tid</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Card style={{ marginTop: 10 }}>
                        <CardHeader className={classes.cardHeader} title={"Prisgrupper"} />
                        <CardContent>
                            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                <EditPriceGroups {...this.props}
                                    forRace={true}
                                    onChange={this.handlePriceGroupsChange}
                                    priceGroups={this.state.addEditVariant.priceGroups}>
                                </EditPriceGroups>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="secondary"
                                onClick={() => {
                                    let o = this.state.addEditVariant;
                                    o.priceGroups.push(ProductVariantPriceGroup.createNew("", "", null, null, true));
                                    this.setState({ addEditVariant: o });
                                }
                                }
                            >
                                Lägg till prisgrupp
                            </Button>
                        </CardActions>
                    </Card>
                    <Card style={{ marginTop: 10, width: '100%' }}>
                        <CardHeader className={classes.cardHeader} title={"Ålderskategorier"} />
                        <CardContent>
                            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                <EditAgeCategories {...this.props}
                                    productVariant={this.state.addEditVariant}
                                    onChange={this.handleAgeCategoriesChange}
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                    <ProductVariantEntryFieldsCard
                        customFields={this.state.customFields}
                        defaultValue={this.state.addEditVariant.productVariantRaceData.entryFields}
                        onChange={(nextValue) => {
                            let addEditVariant = this.state.addEditVariant;
                            addEditVariant.productVariantRaceData.entryFields = nextValue;
                            this.setState({ addEditVariant: addEditVariant, haveLocalChanges: true });
                        }}
                    />
                    <Card style={{ marginTop: 10, width: '100%' }}>
                        <CardHeader className={classes.cardHeader} title={"Lopphantering"} />
                        <CardContent>
                            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                <ManageRace {...this.props}
                                    productVariant={this.state.addEditVariant}
                                    isFormDirty={this.props.haveChanges || this.state.haveLocalChanges}
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.cancelAddEditVariant} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.create} color="primary">
                    {this.state.addVariant ? "Lägg till" : "Spara"}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort loppet?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    };

    private handleDeleteOk = () => {
        var e = this.props.product;
        e.variants = e.variants.filter(x => x.Id != this.state.deleteConfirmation.Id);
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
        this.props.variantsChanged();
        this.forceUpdate();
    };
}

const tableHeadStyles = ({ palette }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    icon: {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(ProductVariantsRace);
