import React, { Fragment } from "react";
import { Card, CardContent, CardHeader, IconButton, LinearProgress, Theme, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { GridColDef as ColDef, DataGrid, GridValueFormatterParams as ValueFormatterParams, GridValueGetterParams as ValueGetterParams } from "@material-ui/data-grid";
import RefreshIcon from '@material-ui/icons/Refresh';
import { Edit, OpenInBrowser } from "@material-ui/icons";
import { ApiBackend } from "../../providers/apibackend";
import { SupportTicket, SupportTicketStatus, SupportTicketStatusLookup, SupportTicketType, SupportTicketTypeLookup } from "../../model/SupportTicket";
import { RouteComponentProps } from "react-router-dom";
import { B2bCompany } from "../../model/B2bCompany";
import EditSupportTicketDialog from "./EditSupportTicketDialog";
import { vasaloppetDateTimeGetterFormatter } from "../../utilities/date";

interface IProps extends RouteComponentProps, WithStyles {
    typeFilter?: SupportTicketType[];
    statusFilter?: SupportTicketStatus[];
    companies?: B2bCompany[];
    companyId?: string;
}

interface IState {
    loading: boolean;
    tickets: SupportTicket[];
    editTicket: SupportTicket;
}

class SupportTicketsCard extends React.Component<IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            tickets: null,
            editTicket: null
        };

        this.api = new ApiBackend();
    }

    async componentDidMount(): Promise<void> {
        await this.refresh();
    }

    render(): JSX.Element {
        const { classes, companies, companyId } = this.props;
        const { loading, tickets, editTicket } = this.state;

        const currentCompany = companies && editTicket ? companies.find(x => x.id === editTicket.b2bCompanyId) : undefined;

        const columns: ColDef[] = [
            {
                field: 'id',
                headerName: ' ',
                width: 100,
                renderCell: (params: ValueFormatterParams) => {
                    return <>
                        <IconButton color="default" onClick={() => { this.editTicket(params.row as SupportTicket) }}>
                            <Edit fontSize="medium" />
                        </IconButton>
                    </>
                }
            },
            {
                field: 'type',
                headerName: 'Typ',
                width: 250,
                valueGetter: (params: ValueGetterParams) => {
                    const ticket = params.row as SupportTicket;
                    return SupportTicketTypeLookup[ticket.type];
                },
            },
            {
                field: 'status',
                headerName: 'Status',
                width: 150,
                valueGetter: (params: ValueGetterParams) => {
                    const ticket = params.row as SupportTicket;
                    return SupportTicketStatusLookup[ticket.status];
                },
            },
            {
                field: 'message',
                headerName: 'Meddelande',
                minWidth: 400,
                flex: 1
            },
            {
                field: 'created',
                headerName: 'Skapad',
                width: 200,
                valueGetter: (params: ValueGetterParams) => {
                    const ticket = params.row as SupportTicket;
                    return vasaloppetDateTimeGetterFormatter(ticket.created, "---");
                }
            }
        ];

        if (companies && !companyId) {
            columns.push({
                field: 'b2bCompanyId',
                headerName: 'Företag',
                width: 150,
                renderCell: (params: ValueFormatterParams) => {
                    const ticket = params.row as SupportTicket;
                    return <>
                        <Typography variant="body2">{companies.find(x => x.id === ticket.b2bCompanyId)?.name}</Typography>
                        <IconButton color="default" onClick={() => { this.navigateCompany(params.row as any) }}>
                            <OpenInBrowser fontSize="medium" />
                        </IconButton>
                    </>
                }
            });
        }

        return (<>
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    title={
                        <Fragment>
                            <Typography
                                variant="h5"
                                style={{ display: 'inline' }}>
                                Supportärenden
                            </Typography>
                            <RefreshIcon
                                style={{ display: 'inline', verticalAlign: 'middle' }}
                                onClick={this.refresh}
                            />
                        </Fragment>
                    }
                />
                <CardContent>
                    {loading &&
                        <LinearProgress color="secondary" />
                    }
                    <DataGrid
                        autoHeight={true}
                        loading={loading}
                        rows={tickets ?? []}
                        columns={columns}
                        pageSize={10}
                    />
                </CardContent>
            </Card>
            {editTicket &&
                <EditSupportTicketDialog
                    ticketId={this.state.editTicket.id}
                    company={currentCompany}
                    classes={classes}
                    onAbortEdit={() => { this.setState({ editTicket: null }) }}
                    onSave={() => {
                        this.setState({ editTicket: null });
                        this.refresh();
                    }}
                />
            }
        </>);
    }

    private refresh = async (): Promise<void> => {
        this.setState({ loading: true });
        await this.listTickets();
    };

    private listTickets = async (): Promise<void> => {
        const { typeFilter, statusFilter, companyId } = this.props;

        const tickets = await this.api.listSupportTickets(typeFilter, statusFilter, companyId);

        this.setState({
            tickets: tickets,
            loading: false
        });
    };

    private editTicket = (ticket: SupportTicket): void => {
        this.setState({ editTicket: ticket });
    };

    private navigateCompany = (ticket: SupportTicket): void => {
        this.props.history.push(`/companies/manage/${ticket.b2bCompanyId}`);
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(SupportTicketsCard);
