/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { ChangeEvent, Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, WithStyles, withStyles, Button, createStyles, Grid, CardActionArea, TableContainer, Paper, Table, TableHead, TableRow, TableBody, Hidden, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, DialogContent, TextField, Select } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { Event } from '../../model/Event';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { DiplomaConfig } from '../../model/DiplomaConfig';
import { Product } from '../../model/Product';

interface State {
    loading : boolean;
    diplomas: DiplomaConfig[],
    deleteConfirmation : DiplomaConfig;
    showDeleteConfirmation: boolean;
    productCatalogue: Product[];

}

class ManageDiplomas extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;
    constructor(props : RouteComponentProps)
    {
        super(props);
        this.state = {loading: true, diplomas : null, deleteConfirmation: null, showDeleteConfirmation:false, productCatalogue: null};
    }

    componentDidMount() {
        this.setState({loading:true});
        let back = new ApiBackend();
        back.listDiplomas().then((diplomas) => {
            diplomas.sort((x,y) => {
                if (x.eventKey == y.eventKey)
                {
                    return x.diplomaProductVariantName < y.diplomaProductVariantName ? -1: 1;
                }
                return x.eventKey < y.eventKey ? -1: 1}
            );
            this.setState({ diplomas: diplomas, loading:false });
        });
    }
    
    deleteDiploma = (diploma : DiplomaConfig) => () =>{
        this.setState({deleteConfirmation: diploma, showDeleteConfirmation:true});
    }

    render() {
        let { classes } = this.props as any;
        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                
                <Card>

                    <CardHeader className={classes.cardHeader} title="Köpbara diplom" />
                    <CardContent>
                    {this.state.loading &&
                        <LinearProgress color="secondary"/>
                        }
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Event</StyledTableCell>
                                        <StyledTableCell>Produkt</StyledTableCell>
                                        <StyledTableCell>Variant</StyledTableCell>
                                        <StyledTableCell>Prisgrupp SWE</StyledTableCell>
                                        <StyledTableCell>Prisgrupp Ej SWE</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state && this.state.diplomas && this.state.diplomas.map((diploma) => {
                                        return <TableRow>
                                            <StyledTableCell>{diploma.eventKey}</StyledTableCell>
                                            <StyledTableCell>{diploma.diplomaProductName}</StyledTableCell>
                                            <StyledTableCell>{diploma.diplomaProductVariantName}</StyledTableCell>
                                            <StyledTableCell>{diploma.diplomaProductVariantPriceGroupName_SE}</StyledTableCell>
                                            <StyledTableCell>{diploma.diplomaProductVariantPriceGroupName_NonSE}</StyledTableCell>
                                            <StyledTableCell align="right">
                                            <DeleteIcon onClick={this.deleteDiploma(diploma)} className={classes.icon} style={{cursor:'pointer'}} /></StyledTableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table></TableContainer>
                    </CardContent>
                    <CardActionArea component="a">
                        <Button onClick={()=>{}}>Lägg till diplom</Button>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
        {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
        </Fragment>;
    }

    renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort diplomet?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
          </Button>
            </DialogActions>
        </Dialog>;
    }

    renderAddDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Lägg till diplomconfig</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        EventKey
                    </Grid>
                    <Grid item xs={9}>
                        <TextField fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        Diplomprodukt
                    </Grid>
                    <Grid item xs={9}>
                        <Select native>
                            
                        </Select>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
          </Button>
            </DialogActions>
        </Dialog>;
    }


    handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    }

    handleDeleteOk = () => {
        this.setState({loading:true});
        var back = new ApiBackend();
        back.deleteDiploma(this.state.deleteConfirmation).then((res) => {
            if (res){
                back.listDiplomas().then((diplomas) => {
                    this.setState({deleteConfirmation: null, showDeleteConfirmation: false, diplomas: diplomas, loading:false });
                });
            }
            else 
            {
                alert("Kunde inte ta bort diplom!");
            }
        })
        
    }
}



const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default withRouter(withStyles(useStyles)(ManageDiplomas));