import { Theme, WithStyles, createStyles, withStyles, Button, Card, CardContent, CardHeader, CardActions, CircularProgress, TextField, Grid } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { Campaign } from "../../model/Campaign";
import { Product } from "../../model/Product";
import { ApiBackend } from "../../providers/apibackend";
import FirebaseContext from "../../providers/Firebase/context";
import DirtyPageChecker from "../Common/DirtyPageChecker";
import EditCampaignFormContent from "./EditCampaignFormContent";
import editCampaignValidationSchema from "./EditCampaignValidationSchema";

interface IProps extends RouteChildrenProps<{ id: string }> {
}

interface IState {
    campaign: Campaign;
    loading: boolean;
    products: Product[];
    codes: string;
}

class EditCampaign extends React.Component<IProps & WithStyles, IState> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: IProps & WithStyles) {
        super(props);
        this.state = {
            loading: true,
            campaign: null,
            products: null,
            codes: ""
        };
        this.api = new ApiBackend();
    }

    private loadCampaign = async (): Promise<void> => {
        const products = await this.api.listProducts(true);
        const campaign = (this.props.match.params.id) ? await this.api.getCampaign(this.props.match.params.id) : {
            isActive: false
        } as Campaign;

        this.setState({
            loading: false,
            campaign: campaign,
            products: products
        });
    }

    async componentDidMount(): Promise<void> {
        await this.loadCampaign();
    }

    private getTitle = (): string | undefined => {
        const { campaign } = this.state;

        if (!campaign.id) {
            return "Skapa ny kampanj";
        }

        if (campaign?.name) {
            return `Kampanj: ${campaign.name}`;
        }
    };

    private async handleAddCampaignCodes() {
        if (!this.state.codes) {
            alert("Inga koder hittade");
            return;
        }

        var codes = this.state.codes
            .split('\n')
            .filter(a => a !== "" || a.length > 0);

        if (!codes) {
            alert("Kunde inte tolka koder");
            return;
        }

        await this.api.addCampaignCodes(this.state.campaign.id, codes)
            .then((res) => {
                if (res) {
                    alert(`${codes.length} ${codes.length == 1 ? "kod" : "koder"} lades till i kampanjen`);
                    this.setState({ codes: '' });
                } else {
                    alert("Servern kunde inte spara kampanjkoderna - kontrollera koderna för dubletter och tidigare tillagda koder")
                }
            })
            .catch(() => {
                alert("Ett okänt fel uppstod - koderna har troligtvis inte lagts till i kampanjen");
            });

        const campaign = await this.api.getCampaign(this.state.campaign.id);
        this.setState({
            campaign: campaign
        });
    }

    render() {
        const { classes } = this.props;
        const { campaign, products, loading } = this.state;

        const cardHeader = (): string => {
            if (!campaign.id) {
                return "Skapa ny kampanj";
            }

            if (campaign.name) {
                return `Hantera ${campaign.name}`;
            }

            return `Hantera ${campaign.id}`
        };

        return (<>
            {loading &&
                <CircularProgress color="primary" />
            }
            {campaign &&
                <Formik
                    initialValues={campaign}
                    validationSchema={editCampaignValidationSchema}
                    onReset={(values): void => this.setState({ campaign: values })}
                    onSubmit={async (values, { resetForm }) => {
                        const campaign = await this.api.saveOrUpdateCampaign(values);
                        resetForm({ values: campaign });
                        this.setState({
                            campaign: campaign
                        });
                        if (!this.props.match.params.id && campaign.id) {
                            this.props.history.replace({ pathname: `manage/${campaign.id}` })
                        }
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;

                        return (
                            <DirtyPageChecker isDirty={dirty} getTitle={this.getTitle} {...this.props}>
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <Card>
                                        <CardHeader className={classes.cardHeader} title={cardHeader()} />
                                        <CardContent className={classes.form}>
                                            <EditCampaignFormContent {...formik} classes={classes} products={products}></EditCampaignFormContent>
                                        </CardContent>
                                        <CardActions>
                                            <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                            <Button style={{ marginLeft: 10 }} color="secondary" disabled={!dirty} variant="contained" onClick={handleReset}>Avbryt</Button>
                                        </CardActions>
                                    </Card>
                                </form>
                            </DirtyPageChecker>
                        )
                    }}
                </Formik>
            }
            {!!(campaign && campaign.totalNumCodes) &&
                <Card style={{ flex: 1, margin: '15px 0' }}>
                    <CardHeader className={classes.cardHeader} title={"Statistik"}></CardHeader>
                    <CardContent>
                        <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                            <Grid item xs={4}>
                                <TextField disabled={true} style={{ flex: 1, margin: '0 3px' }} label="Totalt antal koder" value={campaign.totalNumCodes ?? ''} fullWidth />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField disabled={true} style={{ flex: 1, margin: '0 3px' }} label="Använda koder" value={campaign.usedNumCodes ?? ''} fullWidth />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField disabled={true} style={{ flex: 1, margin: '0 3px' }} label="Kvar att använda" value={(campaign.totalNumCodes - campaign.usedNumCodes) ?? ''} fullWidth />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
            {campaign && campaign.id &&
                <Card style={{ flex: 1, margin: '15px 0' }}>
                    <CardHeader className={classes.cardHeader} title={"Lägg till kampanjkoder"}></CardHeader>
                    <CardContent>
                        <TextField label="Ange en kod per rad" value={this.state.codes} onChange={(event) => { this.setState({ 'codes': event.target.value }) }} fullWidth minRows={6} multiline={true} />
                    </CardContent>
                    <CardActions>
                        <Button color="secondary" variant="contained" disabled={!this.state.codes} onClick={() => { this.handleAddCampaignCodes(); }}>Lägg till kampanjkoder</Button>
                    </CardActions>
                </Card>
            }
        </>);
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(EditCampaign));
