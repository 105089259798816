import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid/Grid";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ApiBackend } from "../../providers/apibackend";
import { ProductCatalogue } from "../../model/ProductCatalogue";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import CardContent from "@material-ui/core/CardContent/CardContent";
import { GridCellParams as CellParams, GridColDef as ColDef, DataGrid, GridValueFormatterParams as ValueFormatterParams } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import EditCatalogueDialog from "./EditCatalogueDialog";
import IconButton from "@material-ui/core/IconButton/IconButton";
import { OpenInBrowser, ArrowForward } from "@material-ui/icons";
import { ActiveProductCatalogue } from "../../model/ActiveProductCatalogue";
import withConfirm, { IConfirmContext } from "../Common/dialog/Confirm";
import withToaster, { IToasterContext } from "../Common/Toaster";
import { MenuItem, TextField } from "@material-ui/core";

type IProps = RouteComponentProps & WithStyles & IConfirmContext & IToasterContext;
type EditMode = "in-app" | "new-tab";

interface IState {
    loadingCatalogues: boolean;
    loadingActiveCatalogues: boolean;
    catalogues: ProductCatalogue[];
    activeCatalogues: ActiveProductCatalogue[];
    editCatalogue?: ProductCatalogue;
}

class ManageCatalogues extends React.Component<IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.api = new ApiBackend();

        this.state = {
            loadingCatalogues: false,
            loadingActiveCatalogues: false,
            catalogues: [],
            activeCatalogues: []
        };
    }

    async componentDidMount(): Promise<void> {
        await this.fetchAllData();
    }

    render(): JSX.Element {
        const { classes } = this.props;
        const { loadingCatalogues, loadingActiveCatalogues, catalogues, activeCatalogues, editCatalogue } = this.state;

        const minaSidorCatalog = activeCatalogues?.find(x => x.catalogueKey === "minasidor");
        const b2bCatalog = activeCatalogues?.find(x => x.catalogueKey === "b2b");
        const activeCatalogesDisabled = loadingCatalogues || loadingActiveCatalogues;

        const columns: ColDef[] = [
            {
                field: 'FAKE_1',
                headerName: ' ',
                width: 150,
                renderCell: (params: ValueFormatterParams) => {
                    return <>
                        <IconButton color="default" onClick={() => { this.editCatalogue(params.row as ProductCatalogue, "new-tab") }}>
                            <OpenInBrowser fontSize="medium" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editCatalogue(params.row as ProductCatalogue, "in-app") }}>
                            <ArrowForward fontSize="medium" />
                        </IconButton>
                    </>;
                }
            },
            {
                field: 'name',
                headerName: 'Namn',
                minWidth: 200,
                flex: 0.3
            },
            {
                field: 'description',
                headerName: 'Beskrivning',
                minWidth: 200,
                flex: 0.7
            },
            {
                field: ' ',
                headerName: '',
                width: 100,
                renderCell: (params: ValueFormatterParams) => {
                    return (
                        <IconButton color="default" onClick={() => { this.deleteCatalogue(params.row as ProductCatalogue) }}>
                            <DeleteIcon fontSize="medium" />
                        </IconButton>
                    );
                }
            }
        ];

        return (<>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card key="a">
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <>
                                    <Typography variant="h5" style={{ display: 'inline' }}>Aktiva produktkataloger </Typography>
                                    <RefreshIcon
                                        style={{ display: 'inline', verticalAlign: 'middle' }}
                                        onClick={this.refreshActiveCatalogues}
                                    />
                                </>
                            }
                        />
                        <CardContent>
                            {loadingActiveCatalogues &&
                                <LinearProgress color="secondary" />
                            }
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="minasidor-select"
                                        name="minasidor-select"
                                        disabled={activeCatalogesDisabled}
                                        select
                                        style={{ flex: 1, margin: '0 3px' }}
                                        type="select"
                                        label="Mina sidor"
                                        InputLabelProps={{ shrink: true }}
                                        value={minaSidorCatalog?.catalogueId ?? ""}
                                        fullWidth
                                        onChange={this.onMinaSidorChange}
                                    >
                                        {catalogues && catalogues.map((x) => {
                                            return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>;
                                        })}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="b2b-select"
                                        name="b2b-select"
                                        disabled={activeCatalogesDisabled}
                                        select
                                        style={{ flex: 1, margin: '0 3px' }}
                                        type="select"
                                        label="Företagssidor"
                                        InputLabelProps={{ shrink: true }}
                                        value={b2bCatalog?.catalogueId ?? ""}
                                        fullWidth
                                        onChange={this.onB2bChange}
                                    >
                                        {catalogues && catalogues.map((x) => {
                                            return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>;
                                        })}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <>
                                    <Typography variant="h5" style={{ display: 'inline' }}>Produktkataloger </Typography>
                                    <RefreshIcon
                                        style={{ display: 'inline', verticalAlign: 'middle' }}
                                        onClick={this.refreshCatalogues}
                                    />
                                </>
                            }
                        />
                        <CardContent>
                            <Box mb={1}>
                                <Button variant="contained" onClick={this.createCatalogue}>Lägg till katalog</Button>
                            </Box>

                            {loadingCatalogues &&
                                <LinearProgress color="secondary" />
                            }
                            <DataGrid
                                autoHeight={true}
                                loading={loadingCatalogues}
                                rows={catalogues ?? []}
                                columns={columns}
                                pageSize={10}
                                disableSelectionOnClick
                                onCellClick={this.onCellClick}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {editCatalogue &&
                <EditCatalogueDialog
                    catalogue={editCatalogue}
                    onAbortEdit={() => {
                        this.setState({ editCatalogue: null });
                        this.fetchAllData();
                    }}
                    onSave={() => {
                        this.setState({ editCatalogue: null });
                        this.fetchAllData();
                    }}
                />
            }
        </>);
    }

    private refreshCatalogues = async () => {
        this.setState({ loadingCatalogues: true });
        const catalogues = await this.api.listProductCatalogues();
        this.setState({ catalogues: catalogues, loadingCatalogues: false });
    };

    private refreshActiveCatalogues = async () => {
        this.setState({ loadingActiveCatalogues: true });
        const activeCatalogues = await this.api.listActiveCatalogues();
        this.setState({ activeCatalogues: activeCatalogues, loadingActiveCatalogues: false });
    };

    private createCatalogue = () => {
        const catalogue = new ProductCatalogue();
        catalogue.products = [];
        this.setState({ editCatalogue: catalogue });
    };

    private fetchAllData = async (): Promise<void> => {
        const work = [
            this.refreshCatalogues(),
            this.refreshActiveCatalogues()
        ];
        await Promise.all(work);
    };

    private editCatalogue = (catalogue: ProductCatalogue, mode: EditMode): void => {
        switch (mode) {
            case "in-app":
                this.props.history.push(`manage/${catalogue.id}`);
                break;
            case "new-tab":
                window.open(`manage/${catalogue.id}`);
                break;
        }
    };

    private onCellClick = (param: CellParams): void => {
        if (["name", "description"].some(x => x === param.field)) {
            this.editCatalogue(param.row as ProductCatalogue, "in-app")
        }
    };

    private onMinaSidorChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { catalogues } = this.state;
        const catalogue = catalogues.find(x => x.id === ev.target.value);

        this.setState({ loadingActiveCatalogues: true });
        const activeCatalog: ActiveProductCatalogue = {
            catalogueKey: "minasidor",
            catalogueId: catalogue.id
        };
        await this.api.addOrUpdateActiveProductCatalogue(activeCatalog);
        await this.refreshActiveCatalogues();
    };

    private onB2bChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { catalogues } = this.state;
        const catalogue = catalogues.find(x => x.id === ev.target.value);

        this.setState({ loadingActiveCatalogues: true });
        const activeCatalog: ActiveProductCatalogue = {
            catalogueKey: "b2b",
            catalogueId: catalogue.id
        };
        await this.api.addOrUpdateActiveProductCatalogue(activeCatalog);
        await this.refreshActiveCatalogues();
    };

    private deleteCatalogue = async (catalogue: ProductCatalogue): Promise<void> => {
        const confirmResult = await this.props.showConfirm(`Du håller på att ta bort katalogen: ${catalogue.name}. Vill du fortsätta?`);

        if (!confirmResult) {
            return;
        }
        this.setState({ loadingCatalogues: true });
        const result = await this.api.deleteProductCatalogue(catalogue.id);
        if (result) {
            await this.refreshCatalogues();
        } else {
            this.props.showToast(`Kunde inte ta bort katalogen: ${catalogue.name}`, "error");
            this.setState({ loadingCatalogues: false });
        }
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withConfirm(withToaster(withStyles(useStyles)(ManageCatalogues))));