import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import { OrderItem } from "../../../../model/OrderItem";
import ManageOrderRebookItem, { PayWith } from "../../../Orders/ManageOrderEditItems/ManageOrderRebookItem";
import { ApiBackend } from "../../../../providers/apibackend";
import { Order } from "../../../../model/Order";
import withToaster, { IToasterContext } from "../../../Common/Toaster";
import { OrderContact } from "../../../../model/OrderContact";
import { Entry } from "../../../../model/Entry";
import { RebookOrderItemsRequest } from "../../../../providers/models";

interface IProps {
    sourceOrderId: string;
    sourceOrderItemId: string;
    onRebooked: (newOrderId: string, openPaymentDialog: boolean) => void;
    onAbort: () => void;
}

interface IHandleDiffWith {
    payWith: PayWith;
    account: string;
    comment: string;
}

const entryToOrderContact = (entry: Entry): OrderContact => {
    return {
        firstName: entry.firstName,
        lastName: entry.lastName,
        email: entry.email,
        phone: entry.phone,
        address: entry.address,
        postalcode: entry.postalCode,
        city: entry.city,
        country: entry.country,
        loggedInUserId: entry.personId
    } as OrderContact
};

const RebookDialog = ({ sourceOrderId, sourceOrderItemId, onRebooked, onAbort, showToast }: IProps & IToasterContext) => {
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null as Order);
    const [rebookOrderItem, setRebookOrderItem] = useState(null as OrderItem);
    const [repayReason, setRepayReason] = useState(null as string);
    const [sendOrderEmail, setSendOrderEmail] = useState(true);
    const [handleDiffWith, setHandleDiffWith] = useState(null as IHandleDiffWith);
    const [rebookNewOrder, setRebookNewOrder] = useState(null as Order);
    const [rebookFormIsValid, setRebookFormIsValid] = useState(false);
    const [saving, setSaving] = useState(false);

    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();

    useEffect(() => {
        didMount.current = true;

        init();

        // unmount
        return () => { didMount.current = false };
    }, []);

    const init = async (): Promise<void> => {
        if (!didMount.current) {
            return;
        }

        setLoading(true);

        const orderResp = await backend.retreiveOrder(sourceOrderId);
        const orderItem = orderResp.items.find(x => x.Id === sourceOrderItemId);

        if (didMount.current) {
            setOrder(orderResp);
            setRebookOrderItem(orderItem);
            setLoading(false);
        }
    };

    const handleSave = async () => {
        setSaving(true);

        try {
            const toCreate = rebookNewOrder;
            if (rebookOrderItem.entry) {
                //Om det är en anmälan, sätt entryns uppgifter som ordercontact
                rebookNewOrder.contact = entryToOrderContact(rebookOrderItem.entry);
            }
            else {
                rebookNewOrder.contact = order.contact;
            }
            rebookNewOrder.locale = order.locale;

            const createdOrder = await backend.adminCreateOrder(toCreate, !sendOrderEmail);
            if (!createdOrder) {
                throw new Error("Something went wrong when creating order");
            }

            let openPaymentDialog = false;
            let payWith = handleDiffWith.payWith;
            if (payWith === "card_swish") {
                payWith = "none";
                openPaymentDialog = true;
            }

            const request: RebookOrderItemsRequest = {
                orderItemId: rebookOrderItem.Id,
                rebookedBy: ApiBackend.getCurrentUserName(),
                rebookedReason: `Ombokad till ${createdOrder.publicId}: ${repayReason}`,
                newOrderId: createdOrder.id,
                handleDiffWith: payWith,
                handleDiffWithAccount: handleDiffWith.account,
                handleDiffWithAccountComment: handleDiffWith.comment,
                suppressEmailToCustomer: !sendOrderEmail
            };
            await backend.rebookOrderItems(order.id, request);

            onRebooked(createdOrder.id, openPaymentDialog);
        } catch (e) {
            showToast("Någonting gick fel vid skapande av order", "error");
        } finally {
            setSaving(false);
        }
    };

    const handleAbort = () => {
        setRebookNewOrder(null);
        onAbort();
    };

    const render = () => {
        const saveDisabled = !repayReason || !rebookFormIsValid || saving;

        return (
            <Dialog
                disableEscapeKeyDown
                maxWidth="lg"
                aria-labelledby="confirmation-dialog-title"
                open={true}>
                <DialogTitle id="confirmation-dialog-title">
                    Omboka
                </DialogTitle>
                <DialogContent dividers>
                    {loading &&
                        <LinearProgress color="secondary" />
                    }
                    {rebookOrderItem &&
                        <ManageOrderRebookItem
                            item={rebookOrderItem}
                            text={repayReason}
                            onTextChanged={(nextValue) => {
                                setRepayReason(nextValue);
                            }}
                            onOrderChanged={(nextValue, formIsValid) => {
                                setRebookNewOrder(nextValue);
                                setRebookFormIsValid(formIsValid);
                            }}
                            onSetHandleDiffWith={(handleDiffWith: PayWith, handleRebookDiffWithAccount: string, handleRebookDiffAccountComment: string, formIsValid : boolean) => {
                                setHandleDiffWith({
                                    payWith: handleDiffWith,
                                    account: handleRebookDiffWithAccount,
                                    comment: handleRebookDiffAccountComment
                                });
                                setRebookFormIsValid(formIsValid)
                            }}
                            onSendOrderEmailChanged={(nextValue) => {
                                setSendOrderEmail(nextValue);
                            }}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disabled={saveDisabled}
                        onClick={handleSave}>
                        Spara
                    </Button>
                    <Button variant="contained" style={{ marginLeft: 5 }}
                        onClick={handleAbort}>
                        Avbryt
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return render();
}

export default withToaster(RebookDialog);
