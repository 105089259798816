/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react';
import { Theme, createStyles, Grid, WithStyles, TextField, Button, withStyles, Select } from '@material-ui/core';
import { ApiBackend } from '../../providers/apibackend';
import { Product } from '../../model/Product';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PointOfSale } from '../../model/PointOfSale';
import { POSSetting } from '../../model/POSSetting';
import { DATE_FORMAT, vasaloppetMoment } from '../../utilities/date';

interface props {
    close: () => void;
}
interface State {
    product: Product;
    exportType: string;
    exportDate: string;
    exportYear: number;
    exportMonth: number;
    downloading: boolean;
    exportVerDate: string;
    exportRowComment: string;
    pointOfSales: PointOfSale[];
    pointOfSale: string;
    salePlaces: POSSetting[],
    salePlace: string;
}

/** Add Product -- Create a product
 */
class AccountingExportDialog extends React.Component<WithStyles & props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            pointOfSale: "", exportDate: vasaloppetMoment().format(DATE_FORMAT),
            pointOfSales: null, exportType: 'accounting', product: null,
            exportYear: new Date().getFullYear(), salePlaces: null, salePlace: null, exportMonth: new Date().getMonth() + 1, downloading: false, exportVerDate: vasaloppetMoment().format(DATE_FORMAT), exportRowComment: ''
        }
        let back = new ApiBackend();
        back.listPointOfSales().then((pos) => {
            this.setState({ pointOfSales: pos });
        });
        back.getGeneral("pos").then((sellPlaces) => {
            this.setState({ salePlaces: sellPlaces });
        })
    }

    handleExportTypeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ exportType: ev.target.value });
    }
    onChange = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let o = {} as any;
        o[prop] = ev.target.value;
        this.setState(o);
    }

    download = async () => {
        this.setState({ downloading: true });
        let back = new ApiBackend();
        if (this.state.exportType == "accounting") {
            await back.downloadAccountingXlsx(this.state.exportYear, this.state.exportMonth, this.state.exportVerDate, this.state.exportRowComment, this.state.pointOfSale);
        }
        else if (this.state.exportType == "kassa") {
            await back.downloadAccountingDailyReport(this.state.exportDate);
        }
        this.setState({ downloading: false });
    }

    getExportYears() {
        let ret = [];
        for (let i = 2020; i < 2050; i++) {
            ret.push(<option value={i}>{i}</option>);
        }
        return ret;
    }

    render() {
        let { classes } = this.props;
        return <div>
            <Grid container className={classes.root} spacing={2} style={{ width: '100%', margin: 0, padding: 10, paddingTop: 0 }}>
                <Grid item xs={12}>

                    <FormControlLabel
                        control={<Radio
                            checked={this.state.exportType === 'accounting'}
                            onChange={this.handleExportTypeChange}
                            value="accounting"
                        />}
                        label="Bokföring"
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        control={<Radio
                            checked={this.state.exportType === 'kassa'}
                            onChange={this.handleExportTypeChange}
                            value="kassa"
                        />}
                        label="Kassarapport"
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        control={<Radio
                            checked={this.state.exportType === 'csv'}
                            onChange={this.handleExportTypeChange}
                            value="csv"
                        />}
                        label="CSV"
                        labelPlacement="start"
                    />
                </Grid>

                {this.state.exportType == "accounting" && <Fragment>
                    <Grid item xs={12}>
                        Period:&nbsp;
                        <Select native label="År" value={this.state.exportYear} onChange={this.onChange("exportYear")}>
                            {this.getExportYears()}
                        </Select>
                        <Select native label="Månad" value={this.state.exportMonth} onChange={this.onChange("exportMonth")}><option value="1">Jan</option><option value="2">Feb</option><option value="3">Mar</option><option value="4">Apr</option><option value="5">Maj</option><option value="6">Jun</option><option value="7">Jul</option><option value="8">Aug</option><option value="9">Sep</option><option value="10">Okt</option><option value="11">Nov</option><option value="12">Dec</option></Select>
                    </Grid>
                    <Grid item xs={12}>
                        Betalningsätt:&nbsp;
                        <Select native label="Betalningskanal" value={this.state.pointOfSale} onChange={this.onChange("pointOfSale")}>
                            <option value="">Swedbank Pay</option>
                            {this.state && this.state.pointOfSales && this.state.pointOfSales.map((x) => {
                                return <option value={x.id}>{x.name}</option>;
                            })}
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        Ver. Datum:&nbsp;<TextField value={this.state.exportVerDate} onChange={this.onChange("exportVerDate")} />
                    </Grid>
                    <Grid item xs={12}>
                        Radkommentar:&nbsp;<TextField value={this.state.exportRowComment} onChange={this.onChange("exportRowComment")} />
                    </Grid>
                </Fragment>}
                {this.state.exportType == "kassa" && <Fragment>
                    <Grid item xs={12}>
                        Dag:&nbsp;
                        <TextField type="date" value={this.state.exportDate} onChange={this.onChange("exportDate")} />
                    </Grid>
                </Fragment>}
                <Button variant="contained" onClick={this.download}>{this.state.downloading ? <CircularProgress /> : null}Hämta export</Button>
                <Button variant="contained" onClick={() => this.props.close()}>Avbryt</Button>
            </Grid></div>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);


export default withStyles(useStyles)(AccountingExportDialog);