import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import { Entry } from "../../../../model/Entry";
import ManageOrder from "../../../Orders/ManageOrder";
import RebookDialog from "./RebookDialog";
import { RouteComponentProps, withRouter } from "react-router-dom";
import OrderPaymentDialog from "../../../Orders/Payments/OrderPaymentDialog";
import { FormikProps } from "formik";
import PersonChangeDialog from "./PersonChangeDialog";
import { Product } from "../../../../model/Product";
import SplitTeamDialog from "./SplitTeamDialog";
import { ApiBackend } from "../../../../providers/apibackend";
import { Order } from "../../../../model/Order";

interface IProps extends FormikProps<Entry> {
    entryProduct: Product;
    isParentDialog: boolean;
    onChange: () => void;
}

const EditEntryActions = ({ entryProduct, isParentDialog, values, dirty, onChange, history }: IProps & RouteComponentProps) => {
    const [showOrderDialog, setShowOrderDialog] = useState(false);
    const [showRebookDialog, setShowRebookDialog] = useState(false);
    const [showPersonChangeDialog, setShowPersonChangeDialog] = useState(false);
    const [showSplitTeamDialog, setShowSplitTeamDialog] = useState(false);
    const [rebookedOrderId, setRebookedOrderId] = useState(null as string);
    const [order, setOrder] = useState(null as Order);

    const backend = new ApiBackend();

    useEffect(() => {
        const init = async () => {
            const orderResult = await backend.retreiveOrder(values.orderId);
            setOrder(orderResult);
        };

        init();
    }, []);

    const handleDone = () => {
        if (isParentDialog) {
            onChange();
        } else {
            history.push("/entries/manage");
        }
    };

    const showSplitTeamButton = (): boolean => {
        const variant = entryProduct.variants.find(x => x.Id === values.variantId);
        return variant?.productVariantRaceData?.type === "team";
    };

    const canChangePerson = (): boolean => {
        if (!order) {
            return false;
        }

        if (dirty) {
            return false;
        }

        return order.Status === "Completed" && values.status === "ACTIVE";
    };

    const canRebook = (): boolean => {
        if (!order) {
            return false;
        }

        if (dirty) {
            return false;
        }

        return order.Status === "Completed" && values.status === "ACTIVE";
    };

    const render = () => {
        if (!order) {
            return null;
        }

        return (<>
            <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                <Button
                    onClick={() => {
                        setShowOrderDialog(true);
                    }}
                >
                    Visa order
                </Button>
                <Button
                    disabled={!canRebook()}
                    onClick={() => {
                        setShowRebookDialog(true);
                    }}
                >
                    Byt klass / Omboka
                </Button>
                <Button
                    disabled={!canChangePerson()}
                    onClick={() => {
                        setShowPersonChangeDialog(true);
                    }}
                >
                    Personbyte
                </Button>
                {showSplitTeamButton() &&
                    <Button
                        disabled={dirty}
                        onClick={() => {
                            setShowSplitTeamDialog(true);
                        }}
                    >
                        Dela upp lag
                    </Button>
                }
            </ButtonGroup>

            {showOrderDialog &&
                <ManageOrder orderId={values.orderId}
                    close={() => {
                        setShowOrderDialog(false);
                        onChange();
                    }}
                />
            }
            {showRebookDialog &&
                <RebookDialog
                    sourceOrderId={values.orderId}
                    sourceOrderItemId={values.orderItemId}
                    onRebooked={(newOrderId: string, openPaymentDialog: boolean) => {
                        setShowRebookDialog(false);

                        if (newOrderId && openPaymentDialog) {
                            setRebookedOrderId(newOrderId);
                        } else {
                            handleDone();
                        }
                    }}
                    onAbort={() => {
                        setShowRebookDialog(false);
                    }}
                />
            }
            {rebookedOrderId &&
                <OrderPaymentDialog
                    orderId={rebookedOrderId}
                    onComplete={() => {
                        setRebookedOrderId(null);
                        handleDone();
                    }}
                    onAbort={() => {
                        setRebookedOrderId(null);
                    }}
                />
            }
            {showPersonChangeDialog &&
                <PersonChangeDialog
                    entry={values}
                    onComplete={() => {
                        setShowPersonChangeDialog(false);
                        handleDone();
                    }}
                    onAbort={() => {
                        setShowPersonChangeDialog(false);
                    }}
                />
            }
            {showSplitTeamDialog &&
                <SplitTeamDialog
                    entry={values}
                    entryProduct={entryProduct}
                    onComplete={() => {
                        setShowSplitTeamDialog(false);
                        handleDone();
                    }}
                    onAbort={() => {
                        setShowSplitTeamDialog(false);
                    }}
                />
            }
        </>);
    };

    return render();
};

export default withRouter(EditEntryActions);
