import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, LinearProgress, Theme, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { GridCellParams as CellParams, GridColDef as ColDef, DataGrid, GridValueFormatterParams as ValueFormatterParams } from "@material-ui/data-grid";
import { ArrowForward, OpenInBrowser } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ProductBundle } from "../../model/ProductBundle";
import { ApiBackend } from "../../providers/apibackend";
import withToaster, { IToasterContext } from "../Common/Toaster";
import withConfirm from "../Common/dialog/Confirm";
import CreateBundleDialog from "./CreateBundleDialog";

type IProps = RouteComponentProps & WithStyles & IToasterContext;
type EditMode = "in-app" | "new-tab";

const ManageBundles = ({ classes, history }: IProps) => {
    const [bundles, setBundles] = useState(null as ProductBundle[]);
    const [createBundle, setCreateBundle] = useState(false);
    const [loading, setLoading] = useState(false);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();

    // Run on load
    useEffect(() => {
        didMount.current = true;

        refreshBundles();

        // unmount
        return () => { didMount.current = false };
    }, []);

    const refreshBundles = async (): Promise<void> => {
        setLoading(true);
        const response = await backend.listProductBundles();
        setBundles(response);
        setLoading(false);
    };

    const handleCreateBundleClick = () => {
        setCreateBundle(true);
    };

    const handleEditBundleClick = (bundle: ProductBundle, mode: EditMode) => {
        switch (mode) {
            case "in-app":
                history.push(`manage/${bundle.id}`);
                break;
            case "new-tab":
                window.open(`manage/${bundle.id}`);
                break;
        }
    };

    const onCellClick = (param: CellParams): void => {
        if (["name", "description"].some(x => x === param.field)) {
            handleEditBundleClick(param.row as ProductBundle, "in-app")
        }
    };

    const columns: ColDef[] = [
        {
            field: "FAKE_1",
            headerName: " ",
            width: 150,
            renderCell: (params: ValueFormatterParams) => {
                return <>
                    <IconButton color="default" onClick={() => { handleEditBundleClick(params.row as ProductBundle, "new-tab") }}>
                        <OpenInBrowser fontSize="medium" />
                    </IconButton>
                    <IconButton color="default" onClick={() => { handleEditBundleClick(params.row as ProductBundle, "in-app") }}>
                        <ArrowForward fontSize="medium" />
                    </IconButton>
                </>;
            }
        },
        {
            field: "name",
            headerName: "Namn",
            minWidth: 200,
            flex: 0.3
        },
        {
            field: "description",
            headerName: "Beskrivning",
            minWidth: 200,
            flex: 0.7
        }
    ];

    return (<>
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        className={classes.cardHeader}
                        title={
                            <>
                                <Typography variant="h5" style={{ display: "inline" }}>Produktbundlingar </Typography>
                                <RefreshIcon
                                    style={{ display: "inline", verticalAlign: "middle" }}
                                    onClick={refreshBundles}
                                />
                            </>
                        }
                    />
                    <CardContent>
                        <Box mb={1}>
                            <Button variant="contained" onClick={handleCreateBundleClick}>Lägg till bundle</Button>
                        </Box>

                        {loading &&
                            <LinearProgress color="secondary" />
                        }
                        <DataGrid
                            autoHeight={true}
                            loading={loading}
                            rows={bundles ?? []}
                            columns={columns}
                            pageSize={10}
                            disableSelectionOnClick
                            onCellClick={onCellClick}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

        {createBundle &&
            <CreateBundleDialog
                onAbort={() => {
                    setCreateBundle(false);
                }}
            />
        }
    </>);
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withConfirm(withToaster(withStyles(useStyles)(ManageBundles))));