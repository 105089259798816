/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import { Card, CardHeader, CardContent, Theme, withStyles, createStyles, Grid, LinearProgress, Typography, Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { AddOnSummaryItem, FolksamOrderItem } from '../../model/Order';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { DataGrid, GridColDef as ColDef, GridValueFormatterParams as ValueFormatterParams } from '@material-ui/data-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import ManageOrder from './ManageOrder';
import { DATE_FORMAT, FILE_FORMAT, vasaloppetDateGetterFormatter, vasaloppetDateTimeGetterFormatter, vasaloppetMoment } from '../../utilities/date';

interface State {
    loading: boolean;
    showSummary: boolean;
    orders: FolksamOrderItem[],
    viewOrder: string;
    fromDate: Date;
    toDate: Date;
    summary: AddOnSummaryItem[];
}

class ListFolksamOrders extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { loading: false, orders: [], viewOrder: null, fromDate: new Date(new Date().getFullYear(), 0, 1), toDate: new Date(9999, 11, 31), showSummary: true, summary: null };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        let back = new ApiBackend();
        this.setState({ loading: true });
        if (this.state.showSummary) {
            back.listFolksamSummary(vasaloppetDateGetterFormatter(this.state.fromDate), vasaloppetDateGetterFormatter(this.state.toDate)).then((summary) => {
                this.setState({ summary: summary, loading: false });
            });
        }
        else {
            back.listFolksamOrders(vasaloppetDateGetterFormatter(this.state.fromDate), vasaloppetDateGetterFormatter(this.state.toDate)).then((orders) => {
                this.setState({ orders: orders, loading: false });
            });
        }
    };

    openOrder = (orderId: string) => () => {
        this.setState({ viewOrder: orderId });
    };

    render() {
        let { classes } = this.props as any;
        const columns: ColDef[] = [
            {
                field: 'orderPublicId', headerName: 'OrderID', width: 120,

                renderCell: (params: ValueFormatterParams) => {
                    return <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={this.openOrder(params.row.orderId)}>{params.row.orderPublicId}</Typography>
                }
            },
            { field: 'productName', headerName: 'Produkt', width: 300, valueGetter: (params) => { return params.row.productName } },
            { field: 'productVariantName', headerName: 'Variant', width: 300, valueGetter: (params) => { return params.row.productVariantName } },
            { field: 'Info.firstName', headerName: 'Förnamn', width: 150, valueGetter: (params) => { return params.row.Info.firstName } },
            { field: 'Info.lastName', headerName: 'Efternamn', width: 150, valueGetter: (params) => { return params.row.Info.lastName } },
            { field: 'Info.address', headerName: 'Adress', width: 200, valueGetter: (params) => { return params.row.Info.address } },
            { field: 'Info.postalCode', headerName: 'Postnr', width: 100, valueGetter: (params) => { return params.row.Info.postalCode } },
            { field: 'Info.city', headerName: 'Ort', width: 150, valueGetter: (params) => { return params.row.Info.city } },
            { field: 'Info.country', headerName: 'Land', width: 100, valueGetter: (params) => { return params.row.Info.country } },
            { field: 'Info.nationality', headerName: 'Nationalitet', width: 100, valueGetter: (params) => { return params.row.Info.nationality } },
            { field: 'Info.personalNumber', headerName: 'Personnr', width: 200, valueGetter: (params) => { return params.row.Info.personalNumber } },
            { field: 'Info.email', headerName: 'EPost', width: 200, valueGetter: (params) => { return params.row.Info.email } },
            { field: 'Info.phone', headerName: 'Telefon', width: 200, valueGetter: (params) => { return params.row.Info.phone } },
            { field: 'created', headerName: 'Skapad', width: 170, valueGetter: (params) => { return vasaloppetDateTimeGetterFormatter(params.row.created) } }
        ];

        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Inställningar</Typography></Fragment>} />
                    <CardContent>

                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}><b>Visa</b></Grid>
                            <Grid item xs={12}>
                                <FormControlLabel label="Sammanställning" control={
                                    <Checkbox checked={this.state.showSummary} onChange={(ev) => { this.setState({ showSummary: ev.target.checked }, () => { this.refresh() }) }} />
                                }
                                />
                                <FormControlLabel label="Lista" control={
                                    <Checkbox checked={!this.state.showSummary} onChange={(ev) => { this.setState({ showSummary: !ev.target.checked }, () => { this.refresh() }) }} />
                                }
                                />
                            </Grid>
                            <Grid item xs={3}><b>Från datum</b></Grid>
                            <Grid item xs={3}><b>Till datum</b></Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={2}><b></b></Grid>

                            <Grid item xs={3}><TextField type='date' value={vasaloppetDateGetterFormatter(this.state.fromDate)} onChange={(ev) => {
                                this.setState({ fromDate: vasaloppetMoment(ev.target.value).toDate() }, () => { this.refresh() });
                            }} /></Grid>
                            <Grid item xs={3}><TextField type='date' value={vasaloppetDateGetterFormatter(this.state.toDate)} onChange={(ev) => {
                                this.setState({ toDate: vasaloppetMoment(ev.target.value).toDate() }, () => { this.refresh() });
                            }} /></Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={2}><Button variant="contained" disabled={this.state.loading} onClick={this.exportExcel}>Excelexportera</Button></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>

                <Card>

                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Beställningar Folksam motionsförsäkring</Typography>
                        <RefreshIcon style={{ display: 'inline', verticalAlign: 'middle', cursor: 'pointer' }} onClick={this.refresh} />
                    </Fragment>} />
                    <CardContent>
                        {this.state.loading &&
                            <LinearProgress color="secondary" />
                        }
                        {this.state.showSummary && this.state.summary && <Fragment>
                            <Grid container spacing={2}>
                                <Grid item xs={6}><b>Produkt</b></Grid>
                                <Grid item xs={2}><b>Antal sålda</b></Grid>
                                <Grid item xs={4}><b>Totalt</b></Grid>
                                {this.state.summary.map((x, idx) => {
                                    return <Fragment key={idx}>
                                        <Grid item xs={6}>{x.productName}</Grid>
                                        <Grid item xs={2}>{x.numberOfSold}st</Grid>
                                        <Grid item xs={4}>{x.price * x.numberOfSold}kr</Grid>
                                    </Fragment>
                                })}
                                <Grid item xs={6}><b>SUMMA:</b></Grid>
                                <Grid item xs={2}><b>{this.state.summary.reduce((prev, cur, idx) => prev + cur.numberOfSold, 0)}st</b></Grid>
                                <Grid item xs={4}><b>{this.state.summary.reduce((prev, cur, idx) => prev + cur.numberOfSold * cur.price, 0)}kr</b></Grid>
                            </Grid>
                        </Fragment>
                        }

                        {!this.state.showSummary && this.state.orders &&
                            <DataGrid
                                checkboxSelection
                                autoHeight={true}
                                loading={this.state.loading}
                                rows={this.state.orders ?? []}
                                columns={columns}
                                pageSize={100}
                            />
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
            {this.state && this.state.viewOrder && <ManageOrder orderId={this.state.viewOrder} close={() => { this.setState({ viewOrder: null }); }} />}
        </Fragment>;
    }

    exportExcel = () => {
        if (this.state.showSummary) {
            this.renderXLSXSummary();
        }
        else {
            this.renderXLSX();
        }
    }

    renderXLSXSummary = async () => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Folksamsummering",
            Subject: vasaloppetMoment().format(DATE_FORMAT),
            Author: "Vasaloppet",
            CreatedDate: new Date()
        };

        wb.SheetNames.push("Utlägg");

        //let groupedPanels = await this.getGroupedPanels();

        let data = [['Vasaloppsföreningen Sälen-Mora'],
        [],
        ['Folksam motionsförsäkring ' + vasaloppetMoment(this.state.fromDate).format(FILE_FORMAT) + "-" + vasaloppetMoment(this.state.toDate).format(FILE_FORMAT)],
        [],
        ['Redovisning till:'],
        ['Kostnad Enervit support Vasaloppet 500kr, Öppet Spår 560kr, Vasaloppet 45 300kr'],
        ['Från: Vasaloppet, Rasmus Ericson, rasmus.ericson@vasaloppet.se'],
        [],
        [],
        ["Lopp", "Antal sålda tjänster", "Värde"]];
        let totalNum = 0;
        let totalSum = 0;
        for (let i = 0; i < this.state.summary.length; i++) {
            const row = this.state.summary[i];
            totalNum += row.numberOfSold;
            totalSum += (row.numberOfSold * row.price);
            data.push(
                [
                    row.productName,
                    row.numberOfSold + '',
                    (row.price * row.numberOfSold) + 'kr'
                ]
            );
        }

        data.push(["Summa att betala till Folksam", totalNum + '', totalSum + "kr"]);

        var ws = XLSX.utils.aoa_to_sheet(data);

        wb.Sheets["Utlägg"] = ws;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), 'folksambeställingar_' + vasaloppetMoment(this.state.fromDate).format(FILE_FORMAT) + "-" + vasaloppetMoment(this.state.toDate).format(FILE_FORMAT) + '.xlsx');
    }

    renderXLSX = async () => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Folksambeställningar",
            Subject: vasaloppetMoment().format(DATE_FORMAT),
            Author: "Vasaloppet",
            CreatedDate: new Date()
        };

        wb.SheetNames.push("Beställningar");

        let data = [['Produkt', "Personnummer", "Efternamn", "Förnamn", "Adress", "Postnr", "Stad", "Nationalitet", "Försäljningsdatum", "Värde tillval", "ORDERID", "PRODUCT_NAME", "VARIANT_NAME"]];
        for (let i = 0; i < this.state.orders.length; i++) {
            const row = this.state.orders[i];

            const omfattning = row.productVariantName.toLowerCase() == "stor" ? "Vasaloppet Stor" : "Vasaloppet Bas";
            data.push(
                [
                    omfattning,
                    row.Info.personalNumber,
                    row.Info.lastName,
                    row.Info.firstName,
                    row.Info.address,
                    row.Info.postalCode,
                    row.Info.city,
                    row.Info.nationality,
                    vasaloppetMoment(row.created).format("DD.MM.YY"),
                    row.price.toString(),
                    row.orderPublicId,
                    row.productName,
                    row.productVariantName
                ]
            );
        }

        var ws = XLSX.utils.aoa_to_sheet(data);

        wb.Sheets["Beställningar"] = ws;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), 'folksambeställingar_' + vasaloppetMoment(this.state.fromDate).format(FILE_FORMAT) + "-" + vasaloppetMoment(this.state.toDate).format(FILE_FORMAT) + '.xlsx');
    }

    s2ab(s: any) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {

        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withRouter(withStyles(useStyles)(ListFolksamOrders));