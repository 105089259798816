import React from "react";
import uuidv4 from "react-uuid";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { IUpdateSupportTicketRequest, SupportTicket, SupportTicketTypeLookup } from "../../model/SupportTicket";
import { ApiBackend } from "../../providers/apibackend";
import EditSupportTicketFormContent from "./EditSupportTicketFormContent";
import { Formik } from "formik";
import { B2bCompany } from "../../model/B2bCompany";

interface IProps extends WithStyles {
    ticketId: string;
    company?: B2bCompany;
    onSave: (ticket: SupportTicket) => void;
    onAbortEdit: () => void;
}

interface IState {
    loading: boolean;
    ticket: SupportTicket;
}

class EditSupportTicketDialog extends React.Component<IProps, IState> {
    private readonly api: ApiBackend;
    private readonly formId = `edit-ticket-form-${uuidv4()}`;

    constructor(props: IProps) {
        super(props);

        this.api = new ApiBackend();

        this.state = { ticket: undefined, loading: true };
    }

    async componentDidMount(): Promise<void> {
        await this.getTicket();
    }

    render(): JSX.Element {
        const { onAbortEdit, onSave, classes, company } = this.props;
        const { loading, ticket } = this.state;

        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="edit-support-ticket-title"
            open={true}
        >
            <DialogTitle id="edit-support-ticket-title">{loading ? "Laddar" : this.renderCardTitleContent()}</DialogTitle>

            {loading &&
                (<>
                    <DialogContent dividers>
                        <CircularProgress color="secondary" />
                    </DialogContent>
                </>)
            }
            {ticket &&
                <Formik
                    initialValues={ticket}
                    onReset={(values): void => {
                        this.setState({ ticket: values });
                        onAbortEdit();
                    }}
                    onSubmit={async (values, { resetForm }) => {
                        const result = await this.save(values);

                        if (result) {
                            resetForm({ values: result });
                            onSave(result);
                        }
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;
                        return (<>
                            <DialogContent dividers>
                                <form id={this.formId} autoComplete="off" onSubmit={handleSubmit}>
                                    <EditSupportTicketFormContent {...formik} company={company} classes={classes} />
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button form={this.formId} type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">
                                    Spara
                                </Button>
                                <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={handleReset}>
                                    {dirty ? "Avbryt" : "Stäng"}
                                </Button>
                            </DialogActions>
                        </>)
                    }}
                </Formik>
            }

        </Dialog>;
    }

    private renderCardTitleContent(): JSX.Element | string {
        const { company } = this.props;
        const { ticket } = this.state;

        if (!company) {
            return `Hantera supportärende (${SupportTicketTypeLookup[ticket.type]})`;
        }

        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return <Box {...defaultBoxStyle}>
            {`Hantera supportärende (${SupportTicketTypeLookup[ticket.type]})`}
            <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                {company.name}
            </Box>
        </Box>
    }

    private save = async (values: SupportTicket): Promise<SupportTicket> => {
        this.setState({ loading: true });

        const addedNotes = values.notes?.filter(x => !x.id);
        const request: IUpdateSupportTicketRequest = {
            id: values.id,
            newStatus: values.status,
            notes: addedNotes?.map(x => x.message)
        };
        const ticket = await this.api.updateSupportTicket(values.id, request);
        this.setState({ ticket: ticket, loading: false });
        return ticket;
    };

    private getTicket = async (): Promise<void> => {
        const { ticketId } = this.props;

        this.setState({ loading: true });
        const ticket = await this.api.getSupportTicket(ticketId);
        this.setState({ ticket: ticket, loading: false });
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default EditSupportTicketDialog;
