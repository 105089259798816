/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, InputLabel, Select, MenuItem, Table, TableContainer, TableRow, Paper, TableCell, TableBody, Dialog, DialogActions, DialogTitle, FormControl } from '@material-ui/core';
import { ApiBackend } from '../../providers/apibackend';
import { CodeHolder } from '../../model/CodeHolder';
import { Code } from '../../model/Code';
import TableHead from '@material-ui/core/TableHead/TableHead';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CodesCard from '../Common/CodesCard';
import withToaster, { IToasterContext } from '../Common/Toaster';
import { vasaloppetDateTimeGetterFormatter } from '../../utilities/date';

interface Props extends IToasterContext {
    code: CodeHolder;
    codesChanged: () => void;
}

interface State {
    code: CodeHolder;
    loading: boolean;
    haveChanges: boolean;
    showDeleteConfirmation: boolean;
    deleteConfirmation: Code;
    addCodes: boolean;
    addEditCode: Code;
    codeFilter: CodeFilter;
}

type CodeFilter = "noFilter" | "used" | "unused";

/** 
 * Lists codes for Code
 */
class Codes extends React.Component<WithStyles & Props, State> {

    state: State = {
        code: null as CodeHolder,
        loading: true,
        haveChanges: false,
        showDeleteConfirmation: false,
        deleteConfirmation: null as Code,
        addEditCode: null as Code,
        addCodes: false,
        codeFilter: "noFilter" as CodeFilter
    };

    get codes(): Code[] {
        if (!this.props?.code?.Codes || !this.state?.codeFilter) {
            return [];
        }

        if (this.state.codeFilter === "noFilter") {
            return this.props.code.Codes;
        }

        return this.state.codeFilter === "unused" ?
            this.props.code.Codes?.filter(x => x.numUsages === 0) :
            this.props.code.Codes?.filter(x => x.numUsages >= x.maxUsages);
    }

    constructor(props: WithStyles & Props) {
        super(props);
    }

    editCode = (code: Code) => () => {
        this.setState({ addEditCode: code }, () => {
            const el = document.getElementById("editCodeField");
            if (el) {
                el.focus();
            }
        });
    };

    removeCode = (idx: number) => () => {
        this.setState({ deleteConfirmation: this.props.code.Codes[idx], showDeleteConfirmation: true });
    };

    addCode = () => {
        this.setState({ addCodes: true });
    };

    exportCodesToClipboard = () => {
        const codes = this.codes.map(x => x.code).join("\n");
        navigator.clipboard.writeText(codes);
    };

    onChangeStateProp = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let o = {} as any;
        o[prop] = ev.target.value;
        this.setState(o);
    };

    changeEditField = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let o = this.state.addEditCode as any;
        o[prop] = ev.target.value;
        this.setState({ addEditCode: o });
    };

    saveEdits = () => {
        this.setState({ loading: true });
        var back = new ApiBackend();
        back.updateCode(this.props.code.id, this.state.addEditCode)
            .then(() => {
                this.props.codesChanged();
                this.setState({ loading: false, addEditCode: null });
            });
    };

    render() {
        const { classes, code } = this.props;

        const showEdit = !code.reuseExistingEntries;

        return <Fragment>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Aktiveringskoder"
                        />
                        <CardContent>
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl>
                                        <Button variant="contained" onClick={this.exportCodesToClipboard}>Kopiera koder till urklipp</Button>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl>
                                        <InputLabel htmlFor="code-filter">Filtrera</InputLabel>
                                        <Select
                                            id="code-filter"
                                            label="Filtrera koder"
                                            value={this.state.codeFilter}
                                            onChange={this.onChangeStateProp('codeFilter')}
                                        >
                                            <MenuItem value={"noFilter"}>Alla koder</MenuItem>
                                            <MenuItem value={"unused"}>Oanvända koder</MenuItem>
                                            <MenuItem value={"used"}>Använda koder</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Kod</StyledTableCell>
                                                    <StyledTableCell>Max användningar</StyledTableCell>
                                                    <StyledTableCell>Antal använda</StyledTableCell>
                                                    <StyledTableCell>Skapad av</StyledTableCell>
                                                    <StyledTableCell>Skapad datum</StyledTableCell>
                                                    <StyledTableCell>Ändrad av</StyledTableCell>
                                                    <StyledTableCell>Ändrad datum</StyledTableCell>
                                                    <StyledTableCell style={{ minWidth: 50 }}></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.codes.map((code, idx) => {
                                                    const showDelete = !(code.numUsages && code.numUsages > 0);

                                                    return <TableRow key={idx}>
                                                        <StyledTableCell>
                                                            <div style={{ whiteSpace: "nowrap" }}>
                                                                {code.code}
                                                            </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {this.state.addEditCode && this.state.addEditCode.id == code.id ?
                                                                <TextField id="editCodeField" onBlur={this.saveEdits} value={this.state.addEditCode.maxUsages} onChange={this.changeEditField("maxUsages")} />
                                                                : code.maxUsages
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell>{code.numUsages}</StyledTableCell>
                                                        <StyledTableCell>{code.createdBy ?? "---"}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <div style={{ whiteSpace: "nowrap" }}>
                                                                {vasaloppetDateTimeGetterFormatter(code.created)}
                                                            </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell>{code.updatedBy ?? "---"}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <div style={{ whiteSpace: "nowrap" }}>
                                                                {vasaloppetDateTimeGetterFormatter(code.updated)}
                                                            </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            {showEdit &&
                                                                <EditIcon onClick={this.editCode(code)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                            }
                                                            {showDelete &&
                                                                <DeleteIcon onClick={this.removeCode(idx)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                            }
                                                        </StyledTableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            {this.state && this.state.addCodes &&
                                <Grid item xs={12}>
                                    <CodesCard
                                        codeHolderId={this.props.code.id}
                                        codesChanged={(codes: Code[]) => {
                                            navigator.clipboard.writeText(codes.map(x => x.code).join("\n"));
                                            this.props.showToast("Skapade koder är kopierade till urklippet");
                                            this.setState({ addCodes: false });
                                            this.props.codesChanged();
                                        }}
                                    />
                                </Grid>
                            }
                        </CardContent>
                        <CardActionArea component="a">
                            {!this.state.addCodes &&
                                <Button onClick={this.addCode}>Lägg till kod(er)</Button>
                            }
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.showDeleteConfirmation &&
                this.renderConfirmationDialog()
            }
        </Fragment>
    }

    renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort aktiveringskoden?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    }

    handleDeleteOk = () => {
        this.setState({ loading: true });
        const back = new ApiBackend();
        back.deleteCode(this.props.code.id, this.state.deleteConfirmation)
            .then(() => {
                this.props.codesChanged();
                this.setState({ loading: false, deleteConfirmation: null, showDeleteConfirmation: false });
            });
    }
}


const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    icon: {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(withToaster(Codes));
