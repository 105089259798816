import React from "react";
import { IconButton, TextField } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { FormikErrors, FormikProps, FormikTouched, getIn } from "formik";

interface IProps extends FormikProps<any> {
    disabled?: boolean;
    isClearable?: boolean;
    propName: string;
    label: string;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
    values: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    valueGetter?: () => any;
    onChange?: (handler: any) => (e: any) => void;
}

class FormSelectField extends React.Component<IProps> {

    render(): JSX.Element {
        const { children, propName, label, values, touched, errors, handleChange, handleBlur, valueGetter, onChange, setFieldValue, disabled, isClearable } = this.props;

        const nestedProp = propName.indexOf(".") > -1;
        const errorFn = () => {
            if (!nestedProp) {
                return Boolean(touched[propName] && errors[propName]);
            }

            return Boolean(
                getIn(touched, propName) &&
                getIn(errors, propName)
            )
        };

        const helperFn = () => {
            if (!nestedProp) {
                return touched[propName] && errors[propName];
            }

            return (
                getIn(touched, propName) &&
                getIn(errors, propName)
            )
        };

        const changeImpl = onChange ? onChange(handleChange) : handleChange;
        const value = valueGetter ? valueGetter() : values[propName] || "";

        return (
            <TextField
                id={propName}
                name={propName}
                select
                style={{ flex: 1, margin: '0 3px' }}
                label={label}
                InputLabelProps={{ shrink: true }}
                value={value}
                fullWidth
                onChange={changeImpl}
                onBlur={handleBlur}
                error={errorFn()}
                helperText={helperFn()}
                InputProps={{
                    endAdornment: isClearable && !!value ? (
                        <IconButton style={{ padding: 0, marginRight: "1em" }}
                            onClick={(e) => {
                                setFieldValue(propName, null)
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    ) : null
                }}
                disabled={disabled}
            >
                {children}
            </TextField>
        )
    }
}

export default FormSelectField;
