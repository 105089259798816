import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, FormControl, InputLabel, Select } from '@material-ui/core';
import { ApiBackend } from '../../providers/apibackend';
import { Product } from '../../model/Product';
import ManageSeedingRunSeedingForRace from './ManageSeedingRunSeedingForRace';


const ManageSeedingRunSeeding = ()  => {

    const [races, setRaces] = useState(null as Product[]);
    const [race, setRace] = useState(null as string);
    const [openDiag, setOpenDiag] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let back = new ApiBackend();
        back.listProducts().then((res) => {
            let races = res.filter(x=>x.type=="race").sort((x,y) => x.name.localeCompare(y.name));
            setRaces(races);
            setRace(races.length > 0 ? races[0].id : null)

            setLoading(false);
        });

    },[])

    const showRunDiag = () => {

        const selrace = races.find(x=>x.id==race);
        return <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="lg"
        aria-labelledby="confirmation-dialog-title"
        open={true}
    >
        <DialogTitle id="confirmation-dialog-title">Kör seedning för {selrace.name}?</DialogTitle>
        <DialogContent>
            <ManageSeedingRunSeedingForRace race={race} close={() => {
                setOpenDiag(false)}}/>
        </DialogContent>
    </Dialog>;
    }

return <Fragment><Grid container spacing={2}>
    <Grid item xs={12}>
        <Card>

            <CardHeader title="Kör seedning" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl>
                            <InputLabel shrink>Tävling</InputLabel>
                            <Select native value={race} onChange={(ev) => {
                                setRace(ev.target.value as string);
                            }}>
                                {races && races.map((y)=> {
                                    return <option value={y.id}>{y.name}</option>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained"  onClick={() => {setOpenDiag(true)}}>Kör seedning i lopp</Button>
                    </Grid>
                    <Grid item xs={5}>
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    </Grid>
    {openDiag && showRunDiag()}
</Grid>
</Fragment>
}
export default ManageSeedingRunSeeding;
