/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Button, ButtonGroup, Link, Paper, Theme, Typography, WithStyles, createStyles, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { createBrowserHistory } from "history";
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { Profile } from '../model/Profile';
import FirebaseContext from '../providers/Firebase/context';
import Firebase from '../providers/Firebase/firebase';
import { ApiBackend } from '../providers/apibackend';
import EditB2bCompany from './B2b/Company/EditB2bCompany';
import ManageB2bCompanies from './B2b/Company/ManageB2bCompanies';
import ManageCompanyInvoiceRequests from './B2b/Company/ManageCompanyInvoiceRequests';
import CompanyEntryOverview from './B2b/Entries/CompanyEntryOverview';
import EditCampaign from './Campaigns/EditCampaign';
import ManageCampaigns from './Campaigns/ManageCampaigns';
import EditEntry from './Entries/Entry/EditEntry';
import EntryOverview from './Entries/Entry/EntryOverview';
import ManageEntries from './Entries/Entry/ManageEntries';
import EditPerson from './Entries/Person/EditPerson';
import ManagePersons from './Entries/Person/ManagePersons';
import Reservations from './Entries/Reservations';
import AddCode from './Events/AddCode';
import AddEvent from './Events/AddEvent';
import EditCode from './Events/EditCode';
import EditEvent from './Events/EditEvent';
import ManageClubs from './Events/ManageClubs';
import ManageCodes from './Events/ManageCodes';
import FinishProfile from './FinishProfile';
import LoggedIn from './LoggedIn';
import Login from './Login';
import ManageSettings from './ManageSettings';
import ListDiplomaOrders from './Orders/ListDiplomaOrders';
import ListEnervitOrders from './Orders/ListEnervitOrders';
import ListFolksamOrders from './Orders/ListFolksamOrders';
import ListTShirtOrders from './Orders/ListTShirtOrders';
import ListTrippelParticipants from './Orders/ListTrippelParticipants';
import ManageDelivery from './Orders/ManageDelivery';
import ManageOrders from './Orders/ManageOrders';
import OrderOverview from './Orders/Overview/OrderOverview';
import OrderScanner from './Orders/OrderScanner';
import EditBundle from './ProductBundle/EditBundle';
import ManageBundles from './ProductBundle/ManageBundles';
import EditCatalogue from './ProductCatalogue/EditCatalogue';
import ManageCatalogues from './ProductCatalogue/ManageCatalogues';
import AddProduct from './Products/AddProduct';
import EditProduct from './Products/EditProduct';
import ManageFields from './Products/ManageFields';
import ManageProducts from './Products/ManageProducts';
import ManageSeedingTables from './Products/ManageSeedingTables';
import Top from './Top';
import { vasaloppetDateTimeGetterFormatter } from '../utilities/date';
import AccountSettings from './AccountSettings';
import withExpoContext from '../contexts/withExpoContext';
import { IExpoContext } from '../contexts/ExpoContext';
import ManageInvoices from './Invoice/ManageInvoices';
import ManageAccreditations from './Accreditations/ManageAccreditations';

export interface MainStateValue {
    firebase: Firebase;
    isSignedIn: boolean;
    profile: Profile;
    onUpdateProfile: (profile: Profile) => void;
}

interface State {
    value: MainStateValue;
    curView: string;
}

interface IProps {
    expoContext: IExpoContext;
}

/** Main Page is the glue of everything
 * - Also keep the instance of firebase (Provider Context)
 * - Keep the route mapping to components
 */

const history = createBrowserHistory();
class MainPage extends React.Component<IProps & WithStyles, State> {

    constructor(props: IProps & WithStyles) {
        super(props);

        history.listen((location) => {
            // location is an object like window.location
            this.setState({ curView: location.pathname });
        });

        if (CONFIG.environment !== "Prod") {
            document.title = `${document.title} - ${CONFIG.environment}`;
        }

        this.state = {
            curView: history.location.pathname,
            value: {
                firebase: new Firebase(), isSignedIn: false, profile: null, onUpdateProfile: (profile: Profile) => {
                    this.setState({ value: { ...this.state.value, profile: profile } });
                }
            }
        };
        ApiBackend.initialize(this.state.value.firebase);

        this.state.value.firebase.auth().onAuthStateChanged((user) => {
            if (user) {

                let backend = new ApiBackend();
                backend.getProfile().then((profile) => {
                    if (profile == null || !profile.isComplete) {
                        this.setState({ value: { ...this.state.value, isSignedIn: user != null, profile: profile } });
                    }
                    else {
                        this.setState({ value: { ...this.state.value, isSignedIn: user != null, profile: profile } });
                    }
                });
            }
            else {
                this.setState({ value: { ...this.state.value, isSignedIn: user != null, profile: null } });
            }
        });
    }

    setProfile = (profile: Profile) => {
        this.setState({ value: { ...this.state.value, profile: profile } });
    }

    setView = (view: string) => () => {
        history.push(view);
    }

    renderFullMode = (): JSX.Element => {
        const { classes } = this.props;
        const build = vasaloppetDateTimeGetterFormatter(BUILD);

        const menu = [
            ["/", "Startsidan"],
            ["category", "Grunddata"],
            ["/products/manage", "Hantera produkter", "manageproducts"],
            ["/codes/manage", "Hantera koder", "managecodes"],
            ["/codes/customers", "Hantera kunder", "managecustomers"],
            ["/fields/manage", "Hantera anmälningsfält", "manageproducts"],
            ["/campaigns/manage", "Hantera kampanjer", "managecampaigns"],
            ["/sourcedata/clubs", "Hantera klubbar", "admin"],
            ["/seedingtables/manage", "Hantera seedning", "admin"],
            ["category", "Anmälningar"],
            ["/entries/overview", "Anmälningsöversikt", "manageentries"],
            ["/persons/manage", "Hantera personer", "manageentries"],
            ["/entries/manage", "Hantera anmälningar", "manageentries"],
            ["category", "Orderhantering"],
            ["/orders/manage", "Hantera orders", "manageorders"],
            ["/orders/overview", "Orderöversikt", "manageorders"],
            ["/orders/diplomas/manage", "Hantera diplomorders", "manageorders"],
            ["/orders/enervit/manage", "Hantera enervitbeställningar", "manageorders"],
            ["/orders/folksam/manage", "Hantera Folksamförsäkring", "manageorders"],
            ["/orders/tshirts/manage", "Hantera T-Shirtbeställningar", "manageorders"],
            ["/invoices/manage", "Att fakturera", "manageinvoices"],
            ["/orders/trippelparticipants/manage", "Potentiella trippeldeltagare", "manageorders"],
            ["/orders/delivery", "Hantera utlämning", "manageorders"],
            ["/orders/scanner", "QR Scanner", "qrscan"],
            ["category", "Företag"],
            ["/companies/manage", "Hantera företag", "manageb2b"],
            ["/companies/invoicerequests/manage", "Hantera förfrågningar", "manageb2b"],
            ["/companies/entries/overview", "Anmälningsöversikt", "manageb2b"],
            ["category", "Produktkatalog"],
            ["/catalogue/manage", "Hantera produktkataloger", "manageproducts"],
            ["/bundle/manage", "Hantera bundles", "managebundles"],
            ["category", "Ackrediteringar"],
            ["/accreditations/manage", "Hantera ackrediteringar", "manageaccreditations"],
            ["category", "Inställningar"],
            ["/users/manage", "Inställningar", "admin"]
        ];

        let menuPaperStyle = {
            background: "#2772b6", paddingTop: 10, paddingBottom: 10, paddingRight: 10
        };
        if (CONFIG.environment !== "Prod") {
            menuPaperStyle.background = "#ac2e17";
        }

        return (
            <FirebaseContext.Provider value={this.state.value}>
                <Router history={history}>
                    <Top />
                    {this.state.value.isSignedIn ?
                        this.state.value.profile.isComplete ?
                            <Grid container className={classes.root} spacing={2} style={{
                                marginTop: 5,
                                margin: 0,
                                width: '100%',
                            }}>
                                <Grid item xs={3}>
                                    <Paper elevation={3} style={menuPaperStyle}>
                                        {menu.map((val, idx) => {
                                            if (val[0] == "category") {
                                                return <div key={idx} style={{ color: '#fff', borderBottom: '1px solid #aaa', fontSize: 12, paddingLeft: 15, marginTop: 10 }}>{val[1]}</div>
                                            }
                                            else if (val.length == 2 || this.state.value.profile.isInRole(val[2])) {
                                                if (this.state.curView == val[0]) {
                                                    return <div key={idx} className={"menuOpt active"}><Link onClick={this.setView(val[0])}>{val[1]}</Link></div>;
                                                }
                                                else if (val[0] != "/" && this.state.curView.substr(0, val[0].length) == val[0]) {
                                                    return <div key={idx} className={"menuOpt active"}><Link onClick={this.setView(val[0])}>{val[1]}</Link></div>;
                                                }
                                                else {
                                                    return <div key={idx} className={"menuOpt"}>
                                                        <Link color="textPrimary" onClick={this.setView(val[0])}>{val[1]}</Link>
                                                    </div>;
                                                }
                                            }
                                        })}
                                    </Paper>
                                    <Typography variant="body2">Bygge: {build}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <div style={{}}>
                                        <Switch>
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/persons/manage"><ManagePersons /></Route> : null}
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/persons/manage/:id"><EditPerson /></Route> : null}
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/manage"><ManageEntries /></Route> : null}
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/manage/:id"><EditEntry /></Route> : null}
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/overview"><EntryOverview /></Route> : null}
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/overview/:id"><EntryOverview /></Route> : null}
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/reservations"><Reservations /></Route> : null}
                                            {this.state.value.profile.isInRole('admin') ? <Route exact path="/sourcedata/clubs"><ManageClubs /></Route> : null}
                                            {this.state.value.profile.isInRole('manageproducts') ? <Route exact path="/fields/manage"><ManageFields /></Route> : null}
                                            {this.state.value.profile.isInRole('manageproducts') ? <Route exact path="/products/manage"><ManageProducts /></Route> : null}
                                            {this.state.value.profile.isInRole('manageproducts') ? <Route exact path="/products/manage/:id"><EditProduct /></Route> : null}
                                            {this.state.value.profile.isInRole('manageproducts') ? <Route exact path="/products/create"><AddProduct /></Route> : null}
                                            {this.state.value.profile.isInRole('admin') ? <Route exact path="/seedingtables/manage"><ManageSeedingTables /></Route> : null}
                                            {this.state.value.profile.isInRole('manageorders') ? <Route exact path="/orders/manage"><ManageOrders /></Route> : null}
                                            {this.state.value.profile.isInRole('manageorders') ? <Route exact path="/orders/diplomas/manage"><ListDiplomaOrders /></Route> : null}
                                            {this.state.value.profile.isInRole('manageorders') ? <Route exact path="/orders/enervit/manage"><ListEnervitOrders /></Route> : null}
                                            {this.state.value.profile.isInRole('manageorders') ? <Route exact path="/orders/folksam/manage"><ListFolksamOrders /></Route> : null}
                                            {this.state.value.profile.isInRole('manageorders') ? <Route exact path="/orders/tshirts/manage"><ListTShirtOrders /></Route> : null}
                                            {this.state.value.profile.isInRole('manageorders') ? <Route exact path="/orders/trippelparticipants/manage"><ListTrippelParticipants /></Route> : null}
                                            {this.state.value.profile.isInRole('manageorders') ? <Route exact path="/orders/overview"><OrderOverview /></Route> : null}
                                            {this.state.value.profile.isInRole('manageorders') ? <Route exact path="/orders/delivery"><ManageDelivery /></Route> : null}
                                            {this.state.value.profile.isInRole('manageinvoices') ? <Route exact path="/invoices/manage"><ManageInvoices /></Route> : null}
                                            {this.state.value.profile.isInRole('managecampaigns') ? <Route exact path="/campaigns/manage"><ManageCampaigns /></Route> : null}
                                            {this.state.value.profile.isInRole('managecampaigns') ? <Route exact path="/campaigns/manage/:id"><EditCampaign /></Route> : null}
                                            {this.state.value.profile.isInRole('managecampaigns') ? <Route exact path="/campaigns/create"><EditCampaign /></Route> : null}
                                            {this.state.value.profile.isInRole('qrscan') ? <Route exact path="/orders/scanner"><OrderScanner /></Route> : null}
                                            {this.state.value.profile.isInRole('managecodes') ? <Route exact path="/codes/manage"><ManageCodes /></Route> : null}
                                            {this.state.value.profile.isInRole('managecodes') ? <Route exact path="/codes/manage/:id"><EditCode /></Route> : null}
                                            {this.state.value.profile.isInRole('managecodes') ? <Route exact path="/codes/create"><AddCode /></Route> : null}
                                            {this.state.value.profile.isInRole('manageevents') ? <Route exact path="/events/manage/:id"><EditEvent /></Route> : null}
                                            {this.state.value.profile.isInRole('manageevents') ? <Route exact path="/events/create"><AddEvent /></Route> : null}
                                            {this.state.value.profile.isInRole('manageb2b') ? <Route exact path="/companies/manage"><ManageB2bCompanies /></Route> : null}
                                            {this.state.value.profile.isInRole('manageb2b') ? <Route exact path="/companies/manage/:id"><EditB2bCompany /></Route> : null}
                                            {this.state.value.profile.isInRole('manageb2b') ? <Route exact path="/companies/invoicerequests/manage"><ManageCompanyInvoiceRequests /></Route> : null}
                                            {this.state.value.profile.isInRole('manageb2b') ? <Route exact path="/companies/entries/overview"><CompanyEntryOverview /></Route> : null}
                                            {this.state.value.profile.isInRole('manageproducts') ? <Route exact path="/catalogue/manage"><ManageCatalogues /></Route> : null}
                                            {this.state.value.profile.isInRole('manageproducts') ? <Route exact path="/catalogue/manage/:id"><EditCatalogue /></Route> : null}
                                            {this.state.value.profile.isInRole('manageproducts') ? <Route exact path="/bundle/manage"><ManageBundles /></Route> : null}
                                            {this.state.value.profile.isInRole('manageproducts') ? <Route exact path="/bundle/manage/:id"><EditBundle /></Route> : null}
                                            {this.state.value.profile.isInRole('manageaccreditations') ? <Route exact path="/accreditations/manage"><ManageAccreditations /></Route> : null}
                                            {this.state.value.profile.isInRole('admin') ? <Route exact path="/users/manage"><ManageSettings /></Route> : null}
                                            <Route path="/profile"><FinishProfile /></Route>
                                            <Route path="/accountsettings"><AccountSettings /></Route>
                                            <Route path="/"><LoggedIn /></Route>
                                        </Switch>
                                    </div>
                                </Grid>
                            </Grid> :
                            <Route path="/">
                                <FinishProfile />
                            </Route>
                        :
                        <Route path={ROUTES.LANDING} component={Login} />
                    }
                </Router>
            </FirebaseContext.Provider>);
    };

    renderExpoMode = (): JSX.Element => {
        const { classes } = this.props;

        let cssColor = "#2772b6";
        if (CONFIG.environment !== "Prod") {
            cssColor = "#ac2e17";
        }

        return (
            <FirebaseContext.Provider value={this.state.value}>
                <Router history={history}>
                    <Top />
                    {this.state.value.isSignedIn ?
                        this.state.value.profile.isComplete ?
                            <Grid container className={classes.root} spacing={6} style={{
                                marginTop: 5,
                                margin: 0,
                                width: '100%',
                            }}>
                                <Grid item xs={12}>
                                    <ButtonGroup
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        aria-label="split button"

                                    >
                                        <Button
                                            style={{ backgroundColor: cssColor }}
                                            onClick={this.setView("/entries/manage")}
                                        >
                                            Sök anmälan
                                        </Button>
                                        <Button
                                            style={{ backgroundColor: cssColor }}
                                            onClick={this.setView("/persons/manage")}
                                        >
                                            Sök person
                                        </Button>
                                        <Button
                                            style={{ backgroundColor: cssColor }}
                                            onClick={this.setView("/orders/manage")}
                                        >
                                            Sök order
                                        </Button>
                                        <Button
                                            style={{ backgroundColor: cssColor }}
                                            onClick={this.setView("/entries/overview")}
                                        >
                                            Anmälningsöversikt
                                        </Button>
                                        <Button
                                            style={{ backgroundColor: cssColor }}
                                            onClick={this.setView("/orders/overview")}
                                        >
                                            Orderöversikt
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{}}>
                                        <Switch>
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/persons/manage"><ManagePersons /></Route> : null}
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/persons/manage/:id"><EditPerson /></Route> : null}

                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/manage"><ManageEntries /></Route> : null}
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/manage/:id"><EditEntry /></Route> : null}
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/overview"><EntryOverview /></Route> : null}
                                            {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/overview/:id"><EntryOverview /></Route> : null}


                                            {this.state.value.profile.isInRole('manageorders') ? <Route exact path="/orders/manage"><ManageOrders /></Route> : null}
                                            {this.state.value.profile.isInRole('manageorders') ? <Route exact path="/orders/overview"><OrderOverview /></Route> : null}

                                            <Route path="/profile"><FinishProfile /></Route>
                                            <Route path="/accountsettings"><AccountSettings /></Route>
                                            <Route path="/"><LoggedIn /></Route>
                                        </Switch>
                                    </div>
                                </Grid>
                            </Grid> :
                            <Route path="/">
                                <FinishProfile />
                            </Route>
                        :
                        <Route path={ROUTES.LANDING} component={Login} />
                    }
                </Router>
            </FirebaseContext.Provider>);
    };

    render() {
        const { expoContext } = this.props;

        if (expoContext.isExpoLayoutMode) {
            return this.renderExpoMode();
        }

        return this.renderFullMode();
    }
}

const useStyles = ({ palette, spacing }: Theme) =>
    createStyles({
        cardHeader: {
            background: palette.secondary.main,
            color: palette.secondary.contrastText,
            padding: 3
        },
        root: {
            flexGrow: 1
        }
    });

export default withExpoContext(withStyles(useStyles)(MainPage));
