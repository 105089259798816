import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, MenuItem } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldArray, FieldArrayRenderProps, Formik, FormikProps } from "formik";
import React from "react";
import { B2bCompanyProduct, B2bCompanyProductExtraField } from "../../../../model/B2bCompany";
import { StartGroup } from "../../../../model/StartGroup";
import FormCbxField from "../../../Common/FormCbxField";
import FormDateField from "../../../Common/FormDateField";
import FormReadonlyField from "../../../Common/FormReadOnlyField";
import FormSelectField from "../../../Common/FormSelectField";
import FormTextField from "../../../Common/FormTextField";
import editB2bCompanyCustomProductsValidationSchema from "./EditB2bCompanyCustomProductValidationSchema";
import FormAutoCompleteField from "../../../Common/FormAutoCompleteField";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { vasaloppetDateGetterFormatter, vasaloppetIsoDateSetterFormatter } from "../../../../utilities/date";
import CustomProductInvoice from "./CustomProductInvoice";
import { Product } from "../../../../model/Product";

interface IProps {
    sourceProduct: Product;
    productIdx: number;
    product: B2bCompanyProduct;
    aggregationGroupValues: string[];
    startGroups?: StartGroup[];

    onSave: (product: B2bCompanyProduct) => void;
    onClose: () => void;
    onDelete: () => void;
}

class EditB2bCompanyCustomProductDialog extends React.Component<IProps> {

    private readonly fieldTypes = ["choice", "text"];

    constructor(props: IProps) {
        super(props);
    }

    render(): JSX.Element {
        const { product } = this.props;

        return (<Formik
            initialValues={product}
            validationSchema={editB2bCompanyCustomProductsValidationSchema}
            onReset={() => {
                this.props.onClose();
            }}
            onSubmit={(product: B2bCompanyProduct) => {
                this.props.onSave(product);
            }}
        >
            {formik => {
                return this.renderDialog(formik);
            }}
        </Formik>)
    }

    private renderDialog = (formik: FormikProps<B2bCompanyProduct>): JSX.Element => {
        const { values, isValid, dirty, handleSubmit, handleReset, isSubmitting, setFieldValue } = formik;

        const hasB2bInvoicePriceGroupError = values.b2bInvoice && !values.b2bInvoicePriceGroupId;
        const isSaveDisabled = !dirty || !isValid || isSubmitting || hasB2bInvoicePriceGroupError;
        const { aggregationGroupValues, startGroups, sourceProduct } = this.props;
        const hasStartGroups = startGroups && startGroups.length > 0;

        return (
            <Dialog
                open={true}
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>
                    {this.cardTitle()}
                </DialogTitle>
                <DialogContent dividers>
                    <Card style={{ marginTop: 10 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4} >
                                    <FormTextField {...formik}
                                        propName={`name`}
                                        label="Namn"
                                        valueGetter={() => values.name} />
                                </Grid>
                                <Grid item xs={8}>
                                    <FormTextField {...formik}
                                        propName={`description`}
                                        label="Beskrivning"
                                        valueGetter={() => values.description} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormDateField {...formik}
                                        propName={`validFrom`}
                                        label="Giltig från"
                                        valueFormatter={() => vasaloppetDateGetterFormatter(values.validFrom)}
                                        onChange={() => (e: any) => {
                                            setFieldValue("validFrom", vasaloppetIsoDateSetterFormatter(e.target.value));
                                        }} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormDateField {...formik}
                                        propName={`validTo`}
                                        label="Giltig t.o.m."
                                        valueFormatter={() => vasaloppetDateGetterFormatter(values.validTo)}
                                        onChange={() => (e: any) => {
                                            setFieldValue("validTo", vasaloppetIsoDateSetterFormatter(e.target.value, true));
                                        }} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormTextField {...formik}
                                        type="number"
                                        propName={`maxNumRegistrations`}
                                        label="Max antal"
                                        valueGetter={() => values.maxNumRegistrations ?? ""} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormReadonlyField {...formik}
                                        propName={`numRegistred`}
                                        label="Antal anmälda" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormAutoCompleteField {...formik}
                                        propName={`aggregationGroup`}
                                        autoCompleteValues={aggregationGroupValues}
                                        label="Aggregeringsgrupp" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormReadonlyField {...formik}
                                        propName={`aggregationGroupNumRegistered`}
                                        label="Anmälda Agg.grupp" />
                                </Grid>
                                {hasStartGroups &&
                                    <Grid item xs={4}>
                                        <FormSelectField {...formik}
                                            propName={`enforcedStartGroupId`}
                                            label="Forcerad startgrupp"
                                            valueGetter={() => values.enforcedStartGroupId ?? ""}
                                        >
                                            {startGroups.map((x, sIdx) => {
                                                return <MenuItem key={sIdx} value={x.id}>{x.name}</MenuItem>;
                                            })}
                                        </FormSelectField>
                                    </Grid>
                                }
                                <Grid item xs={2}>
                                    <FormCbxField {...formik}
                                        propName={"b2bInvoice"}
                                        label="Ska faktureras"
                                    />
                                </Grid>

                                {values.b2bInvoice &&
                                    <Grid item xs={12}>
                                        <CustomProductInvoice
                                            sourceProduct={sourceProduct}
                                            product={values}
                                            onPriceGroupChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue("b2bInvoicePriceGroupId", ev.target.value);
                                            }}
                                        />
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Extra fält"></CardHeader>
                                        <FieldArray
                                            name={`extraFields`}
                                            render={arrayHelpers => (
                                                <>
                                                    {!!(values.extraFields && values.extraFields.length) && <CardContent>
                                                        {values.extraFields && values.extraFields.map((extraField, eIdx) => {
                                                            return this.renderExtraField(formik, extraField, eIdx, arrayHelpers);
                                                        })}
                                                    </CardContent>
                                                    }
                                                    <CardActions>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => { this.addExtraField(arrayHelpers); }}
                                                        >Lägg till extra fält</Button>
                                                    </CardActions>
                                                </>
                                            )} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => this.props.onDelete()}
                    >Ta bort produkt</Button>
                    <Button
                        variant="contained"
                        onClick={handleReset}
                    >Avbryt</Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={isSaveDisabled}
                        onClick={() => handleSubmit()}
                    >Stäng</Button>
                </DialogActions>
            </Dialog>
        )
    }

    private renderExtraField(
        formik: FormikProps<B2bCompanyProduct>,
        field: B2bCompanyProductExtraField,
        extraFieldIdx: number,
        arrayHelpers: FieldArrayRenderProps): JSX.Element {

        const { setFieldValue } = formik;

        const validValuesGetter = (): string => {
            if (!field.validValues) {
                return "";
            }

            const arr = JSON.parse(field.validValues);
            return arr.join("\n");
        };

        const validValuesSetter = (str: string): void => {
            const arr = str.split("\n");
            setFieldValue(`extraFields[${extraFieldIdx}].validValues`, JSON.stringify(arr));
        };

        const isPersisted = !!field.id;
        const isLocked = !!formik.values.numRegistred && isPersisted;
        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return (
            <Card style={extraFieldIdx === 0 ? { marginTop: 0 } : { marginTop: 10 }} key={extraFieldIdx}>
                <CardContent>
                    <Box {...defaultBoxStyle}>
                        <Box style={{ flex: 1 }}>
                            <Grid container key={extraFieldIdx} spacing={2}>
                                <Grid item xs={3} >
                                    <FormTextField {...formik}
                                        disabled={isLocked}
                                        propName={`extraFields[${extraFieldIdx}].name`}
                                        label="Namn"
                                        valueGetter={() => field.name ?? ""} />
                                </Grid>
                                <Grid item xs={7} >
                                    <FormTextField {...formik}
                                        propName={`extraFields[${extraFieldIdx}].description`}
                                        label="Beskrivning"
                                        valueGetter={() => field.description ?? ""} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormCbxField {...formik}
                                        propName={`extraFields[${extraFieldIdx}].required`}
                                        label="Tvingande"
                                        valueGetter={() => field.required} />
                                </Grid>
                                <Grid item xs={3} >
                                    <FormControlLabel
                                        label="Använd som Team"
                                        control={
                                            <Checkbox
                                                checked={field.useAsTeamInEntry ?? false}
                                                onChange={(ev) => { setFieldValue(`extraFields[${extraFieldIdx}].useAsTeamInEntry`, ev.target.checked) }} />
                                        } />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormSelectField {...formik}
                                        disabled={isLocked}
                                        propName={`extraFields[${extraFieldIdx}].type`}
                                        label="Typ"
                                        valueGetter={() => field.type ?? ""}
                                        onChange={() => (e: any) => {
                                            setFieldValue(`extraFields[${extraFieldIdx}].type`, e.target.value);

                                            if (e.target.value !== "choice") {
                                                setFieldValue(`extraFields[${extraFieldIdx}].validValues`, null)
                                            }
                                        }}
                                    >
                                        {this.fieldTypes.map((fieldType, key) => {
                                            return <MenuItem key={key} value={fieldType}>{fieldType}</MenuItem>;
                                        })}
                                    </FormSelectField>
                                </Grid>
                                {field.type === "choice" &&
                                    <Grid item xs={6} >
                                        <FormTextField {...formik}
                                            propName={`extraFields[${extraFieldIdx}].validValues`}
                                            label="Värden"
                                            minRows={4}
                                            multiline={true}
                                            valueGetter={() => { return validValuesGetter(); }}
                                            onChange={() => (e: any) => {
                                                validValuesSetter(e.target.value);
                                            }} />
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                        <Box>
                            {extraFieldIdx > 0 &&
                                <ExpandLess style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        arrayHelpers.move(extraFieldIdx, extraFieldIdx - 1);
                                    }}
                                />
                            }
                            {extraFieldIdx < formik.values.extraFields.length - 1 &&
                                <ExpandMore style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        arrayHelpers.move(extraFieldIdx, extraFieldIdx + 1);
                                    }}
                                />
                            }
                            <IconButton
                                disabled={isLocked}
                                onClick={() => { arrayHelpers.remove(extraFieldIdx); }}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Box>


                </CardContent>
            </Card>
        )
    };

    private cardTitle = (): string => {
        const { product } = this.props;

        let desc = product.name ? product.name : "Produkt";

        if (product.description) {
            desc += " - ";
            desc += (product.description.length > 40) ? product.description.slice(0, 40 - 1) + '...' : product.description;
        }

        return desc;
    };

    private addExtraField = (arrayHelpers: FieldArrayRenderProps) => {
        arrayHelpers.push({});
    };
}

export default EditB2bCompanyCustomProductDialog;
