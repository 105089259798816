/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react';
import FirebaseContext from "../providers/Firebase/context";
import { Card, CardHeader, CardContent, Theme, WithStyles, withStyles, Button, createStyles, Grid, CardActionArea, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, DialogContent } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ApiBackend } from "../providers/apibackend";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { CronJob, CronJobType, IEnervitReportData, IMikaDownloadData } from "../model/CronJob";
import TextField from "@material-ui/core/TextField/TextField";
import Select from "@material-ui/core/Select/Select";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { vasaloppetDateTimeGetterFormatter } from "../utilities/date";
import ProductList from "./Common/ProductList/ProductList";
import { ProductListChangeEvent } from './Common/ProductList/utils';

interface State {
    loading: boolean;
    jobs: CronJob[],
    newJob: CronJob,
    editJob: CronJob,
    deleteConfirmation: CronJob;
    showDeleteConfirmation: boolean;
}

class ManageCronJobs extends React.Component<RouteComponentProps & WithStyles, State> {
    static contextType = FirebaseContext;

    private readonly api = new ApiBackend();

    constructor(props: RouteComponentProps & WithStyles) {
        super(props);
        this.state = { loading: true, jobs: null, newJob: null, editJob: null, deleteConfirmation: null, showDeleteConfirmation: false };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.api.listCronJobs().then((jobs) => {
            this.setState({ jobs: jobs, loading: false });
        });
    }

    addJob = () => {
        const j = { id: null, type: "mikadownload", data: { url: "", name: "" } } as CronJob;
        this.setState({ newJob: j });
    };

    editJob = (job: CronJob) => () => {
        this.setState({ editJob: job });
    };

    deleteJob = (job: CronJob) => () => {
        this.setState({ deleteConfirmation: job, showDeleteConfirmation: true });
    };

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader className={classes.cardHeader} title="Schemalagda jobb" />
                            <CardContent>
                                {this.state.loading &&
                                    <LinearProgress color="secondary" />
                                }
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Typ</StyledTableCell>
                                                <StyledTableCell>Data</StyledTableCell>
                                                <StyledTableCell>Senat kört</StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state && this.state.jobs && this.state.jobs.map((job) => {
                                                return <TableRow key={job.id}>
                                                    <StyledTableCell>{job.type}</StyledTableCell>
                                                    <StyledTableCell>
                                                        {job.type === "mikadownload" &&
                                                            <>
                                                                <span style={{ fontWeight: "bold" }}>Namn:</span> <span>{(job.data as IMikaDownloadData).name}</span><br />
                                                                <span style={{ fontWeight: "bold" }}>Url:</span> <span>{(job.data as IMikaDownloadData).url}</span>
                                                            </>
                                                        }
                                                        {job.type === "enervitreport" &&
                                                            <>
                                                                <span style={{ fontWeight: "bold" }}>Periodicitet:</span> <span>{(job.data as IEnervitReportData).intervalDays} dagar</span> <br />
                                                                <span style={{ fontWeight: "bold" }}>Mottagare:</span> <span>{(job.data as IEnervitReportData).recipients.join(", ")}</span>
                                                            </>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell>{job.lastRun ? vasaloppetDateTimeGetterFormatter(job.lastRun, "", true) : "aldrig kört"}</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <EditIcon onClick={this.editJob(job)} className={classes.icon} style={{ cursor: "pointer" }} />
                                                        <DeleteIcon onClick={this.deleteJob(job)} className={classes.icon} style={{ cursor: "pointer" }} /></StyledTableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table></TableContainer>
                            </CardContent>
                            <CardActionArea component="a">
                                <Button onClick={this.addJob}>Lägg till jobb</Button>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
                {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
                {this.state && this.state.newJob && this.renderAddJob()}
                {this.state && this.state.editJob && this.renderEditJob()}
            </Fragment>
        );
    }

    setJob = (target: string, job: CronJob) => {
        if (target == "new") {
            this.setState({
                newJob: job
            });
        } else {
            this.setState({
                editJob: job
            });
        }
    };

    onChangeType = (target: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        const job = target == "new" ? this.state.newJob : this.state.editJob;
        const nextType = ev.target.value as CronJobType;
        job.type = nextType;

        if (nextType === "mikadownload") {
            job.data = { url: "", name: "" };
        } else if (nextType === "enervitreport") {
            job.data = { recipients: [], raceProducts: [], intervalDays: 1 };
        }

        this.setJob(target, job);
    };

    renderAddJob() {
        return this.renderJobDialog("new", this.state.newJob);
    }

    renderEditJob() {
        return this.renderJobDialog("edit", this.state.editJob);
    }

    renderMikaData(target: string, data: IMikaDownloadData): JSX.Element {
        const onChange = (target: string, field: keyof IMikaDownloadData) => (ev: React.ChangeEvent<HTMLInputElement>) => {
            const job = target == "new" ? this.state.newJob : this.state.editJob;
            const data = job.data as IMikaDownloadData;

            data[field] = ev.target.value;
            this.setJob(target, job);
        };

        return (<>
            <Grid item xs={12} sm={6}>
                <TextField style={{ flex: 1, margin: "0 3px" }} id="standard-basic" value={data.url} onChange={onChange(target, "url")} label="URL" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField style={{ flex: 1, margin: "0 3px" }} id="standard-basic" value={data.name} onChange={onChange(target, "name")} label="Namn" fullWidth />
            </Grid>
        </>);
    }

    renderEnervitData(target: string, data: IEnervitReportData): JSX.Element {
        let { classes } = this.props;

        const onChange = (target: string, idx: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
            const job = target == "new" ? this.state.newJob : this.state.editJob;
            const data = job.data as IEnervitReportData;

            data.recipients[idx] = ev.target.value;
            this.setJob(target, job);
        };

        const onChangePeriodicity = (target: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
            const job = target == "new" ? this.state.newJob : this.state.editJob;
            const data = job.data as IEnervitReportData;
            data.intervalDays = +ev.target.value;
            this.setJob(target, job);
        }

        const handleChangeProduct = (target: string) => (evt: ProductListChangeEvent) => {
            const job = target == "new" ? this.state.newJob : this.state.editJob;
            const data = job.data as IEnervitReportData;
            data.raceProducts = !!evt.products && evt.products.length > 0 ? evt.products.map(x => x.id) : [];
        };

        const handleAddRecipient = () => {
            const job = target == "new" ? this.state.newJob : this.state.editJob;
            const data = job.data as IEnervitReportData;
            data.recipients.push("");
            this.setJob(target, job);
        };

        const handleRemoveRecipient = (idx: number) => {
            const job = target == "new" ? this.state.newJob : this.state.editJob;
            const data = job.data as IEnervitReportData;
            data.recipients.splice(idx, 1);
            this.setJob(target, job);
        };

        const recipients = data.recipients.map((x, idx) => <>
            <Grid item xs={11}>
                <TextField style={{ flex: 1, margin: "0 3px" }} id="standard-basic" value={x} onChange={onChange(target, idx)} fullWidth />
            </Grid>
            <Grid item xs={1}>
                <DeleteIcon className={classes.icon} style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleRemoveRecipient(idx);
                    }}
                />
            </Grid>
        </>);

        return (<>
            <Grid item xs={12}>
                <TextField style={{ flex: 1, margin: "0 3px" }} id="standard-basic" value={data.intervalDays} onChange={onChangePeriodicity(target)} label="Periodicitet (Dagar)" fullWidth />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel shrink>Gäller för produkter</InputLabel>
                    <ProductList
                        initialValue={data.raceProducts}
                        productTypes={["race", "event"]}
                        multiple
                        onChange={handleChangeProduct(target)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel shrink>Mottagare</InputLabel>
                </FormControl>
            </Grid>
            {recipients}
            <Grid item xs={12}>
                <Button variant="contained" autoFocus
                    onClick={handleAddRecipient} color="primary">
                    Lägg till ny mottagare
                </Button>
            </Grid>
        </>);
    }

    renderJobDialog(target: string, object: CronJob) {
        const { classes } = this.props;

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="sm"
                aria-labelledby="confirmation-dialog-title"
                open={true}
            >
                <DialogTitle>
                    {target == "new" ? "Skapa nytt jobb" : "Editera jobb"}
                </DialogTitle>
                <DialogContent dividers>
                    <form className={classes.form} noValidate autoComplete="off">
                        <Grid container className={classes.root} spacing={2} style={{ width: "100%" }}>
                            <Grid item xs={12}>
                                <FormControl style={{ marginBottom: 5 }} fullWidth>
                                    <InputLabel id="select-label">Typ</InputLabel>
                                    <Select labelId="select-label" value={object.type} variant="filled" onChange={this.onChangeType(target)}>
                                        <MenuItem value="mikadownload">Hämta fil från MIKA ftp</MenuItem>
                                        <MenuItem value="enervitreport">Skicka Enervit-rapport</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {object.type === "mikadownload" &&
                                this.renderMikaData(target, object.data as IMikaDownloadData)
                            }
                            {object.type === "enervitreport" &&
                                this.renderEnervitData(target, object.data as IEnervitReportData)
                            }
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={this.handleAddCancel} color="primary">
                        Avbryt
                    </Button>
                    <Button variant="contained" onClick={target == "new" ? this.createJob : this.updateJob} color="secondary">
                        {target == "new" ? "Skapa" : "Spara"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    createJob = () => {
        this.setState({ loading: true });

        this.api.addCronJob(this.state.newJob).then(() => {
            this.setState({ newJob: null });
            this.api.listCronJobs().then((jobs) => {
                this.setState({ loading: false, jobs: jobs })
            });
        })
    };

    updateJob = () => {
        this.setState({ loading: true })

        this.api.updateCronJob(this.state.editJob).then(() => {
            this.setState({ editJob: null });
            this.api.listCronJobs().then((jobs) => {
                this.setState({ loading: false, jobs: jobs })
            });
        })
    };

    handleAddCancel = () => {
        this.setState({ newJob: null, editJob: null });
    };

    renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort jobbet?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    };

    handleDeleteOk = () => {
        this.setState({ loading: true });

        this.api.deleteCronJob(this.state.deleteConfirmation).then((res) => {
            if (res) {
                this.api.listCronJobs().then((jobs) => {
                    this.setState({ deleteConfirmation: null, showDeleteConfirmation: false, jobs: jobs, loading: false });
                });
            }
            else {
                alert("Kunde inte ta bort jobb!");
            }
        })

    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default withRouter(withStyles(useStyles)(ManageCronJobs));
