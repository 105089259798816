import React from "react";
import { FieldArray, FormikProps } from "formik";
import { Entry, EntryAddonProduct } from "../../../model/Entry";
import { Product } from "../../../model/Product";
import { Card, CardContent, CardHeader, Grid, MenuItem, WithStyles } from "@material-ui/core";
import { ApiBackend } from "../../../providers/apibackend";
import FormTextField from "../../Common/FormTextField";
import { IInsuranceOrServiceOrderItem } from "../../../model/IInsuranceOrServiceOrderItem";

interface IProps extends FormikProps<Entry> {
    entryProduct: Product;
}

interface IState {
    mounted: boolean;
    expanded: boolean;
    addonProducts: Product[];
    entryAddons: (EntryAddonProduct | IInsuranceOrServiceOrderItem)[]
}

class EntryAddonsCard extends React.Component<WithStyles & IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: WithStyles & IProps) {
        super(props);

        this.api = new ApiBackend();
        this.state = { mounted: false, expanded: true, addonProducts: undefined, entryAddons: undefined };
    }

    async componentDidMount(): Promise<void> {
        await this.initData();
    }

    render(): JSX.Element {
        const { values, classes } = this.props;
        const { mounted, expanded, entryAddons } = this.state;

        if (!mounted) {
            return null;
        }

        const hasAddons = entryAddons && entryAddons.length > 0;

        return (
            <Card>
                <CardHeader
                    title="Tillval"
                />
                {expanded && (
                    <CardContent>
                        <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                            {hasAddons && <>
                                {entryAddons.map((addOn, idx) => {
                                    return this.renderAddon(addOn, idx);
                                })}
                            </>}
                        </Grid>
                    </CardContent>
                )}
            </Card>
        )
    }

    private renderAddon = (addon: EntryAddonProduct | IInsuranceOrServiceOrderItem, idx: number): JSX.Element => {
        const { addonProducts } = this.state;

        const productMatch = addonProducts?.find(x => x.id === addon.productId);
        const variantMatch = productMatch?.variants?.find(x => x.Id === addon.productVariantId);
        const priceGroupMatch = variantMatch?.priceGroups?.find(x => x.id === addon.productVariantPriceGroupId);

        if (!priceGroupMatch) {
            return null;
        }

        return (
            <FormTextField key={idx} {...this.props}
                disabled={true}
                propName={`addOns[${idx}].value`}
                label={productMatch.name}
                valueGetter={() => `${variantMatch.Name} (${priceGroupMatch.Name})`} />
        )
    };

    private initData = async (): Promise<void> => {
        const { entryProduct, values } = this.props;
        const { personId } = values;

        const work = [
            this.api.listProducts(),
            this.api.getPersonInsurances(personId),
            this.api.getPersonServices(personId)
        ];

        const [productsResponse, insuranceResponse, serviceResponse] = await Promise.all(work);

        const products = productsResponse as Product[];
        const insurences = insuranceResponse as IInsuranceOrServiceOrderItem[];
        const services = serviceResponse as IInsuranceOrServiceOrderItem[];

        let addons = values.addOns.filter(x => !this.isFolksamOrEnervit(x.productName))

        this.setState({
            mounted: true,
            addonProducts: products.filter(f => entryProduct.addOnProducts?.some(fd => fd === f.id)),
            entryAddons: [...addons, ...insurences, ...services]
        });
    };

    private isFolksamOrEnervit = (productName: string): boolean => {
        const name = productName.toLowerCase();
        return name.includes("folksam") || name.includes("enervit");
    };
}

export default EntryAddonsCard;
