import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import VasaloppetTooltip from "../Common/VasaloppetTooltip";

export type ExternalRefType = {
    sourceId: string | number;
    entityType: "Payment" | "CodeHolder" | "B2bCustomProduct";
    presentationName: string;
    tooltipContent: JSX.Element;
    externalRef: string;
}

interface IProps {
    items: readonly ExternalRefType[];
    onSave: (values: ExternalRefType[]) => void;
    onAbort: () => void;
}

const CollectExternalRefDialog = ({ items, onSave, onAbort }: IProps) => {
    const [values, setValues] = useState(items as ExternalRefType[]);

    const handleRefChange = (id: string | number, value: string) => {
        const idx = values.findIndex((x) => x.sourceId === id);
        const updatedValue = { ...values[idx], externalRef: value };
        const nextValues = [
            ...values.slice(0, idx),
            updatedValue,
            ...values.slice(idx + 1)
        ];
        setValues(nextValues);
    };

    const canSave = () => {
        return values.every(x => !!x.externalRef);
    };

    const render = (): JSX.Element => {
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="collect-external-ref-title"
                open={true}>
                <DialogTitle id="collect-external-ref-title">Lägg till märkningar</DialogTitle>
                <DialogContent dividers style={{ minHeight: 300 }}>
                    <Box display={"flex"} flexDirection={"column"} style={{ gap: "0.25em" }}>
                        {
                            values.map((x, idx) => {
                                const even = idx % 2 === 0;
                                return <Box padding={1} key={idx} bgcolor={even ? "#f1f1f1" : "#f9f9f9"}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <Box display={"flex"} flexDirection={"row"} style={{ gap: "0.25em" }} alignItems={"center"}>
                                                <span>{x.presentationName}</span>
                                                <VasaloppetTooltip title={x.tooltipContent}>
                                                    <HelpIcon fontSize="small" />
                                                </VasaloppetTooltip>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                value={x.externalRef}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleRefChange(x.sourceId, ev.target.value);
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            })
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={!canSave()}
                        onClick={() => { onSave(values) }}
                        color="secondary">Spara</Button>

                    <Button
                        variant="contained"
                        onClick={() => onAbort()}
                        color="secondary">Avbryt</Button>
                </DialogActions>
            </Dialog >
        );
    };

    return render();
};

export default CollectExternalRefDialog;
