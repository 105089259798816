import { Box, Button, ButtonGroup, Grid, WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import { Person } from "../../../model/Person";
import { ApiBackend } from "../../../providers/apibackend";
import RenderPersonFields from "./RenderPersonFields";

interface IProps extends FormikProps<Person> {
    isEditMode: boolean;
}

class EditPersonFormContent extends React.Component<WithStyles & IProps> {
    private readonly apiBackend: ApiBackend;

    constructor(props: WithStyles & IProps) {
        super(props);

        this.apiBackend = new ApiBackend();
    }

    render(): JSX.Element {
        const { isEditMode, classes, values, dirty } = this.props;
        const isLogInAsDisabled = dirty;

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12}>
                    <Grid container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {isEditMode &&
                            <Box className="MuiFormControl-root">
                                <label className="MuiFormLabel-root">
                                    {this.props.values.firstName} på mina sidor:
                                </label>
                                <ButtonGroup disabled={isLogInAsDisabled} color="secondary" aria-label="outlined secondary button group">
                                    <Button
                                        disabled={!values.email}
                                        onClick={() => {
                                            this.handleLogin("email");
                                        }}>
                                        Logga in med epost
                                    </Button>
                                    <Button
                                        disabled={!values.mobileNumber}
                                        onClick={() => {
                                            this.handleLogin("phone");
                                        }}>
                                        Logga in med mobil
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        }
                    </Grid>
                </Grid>
                <RenderPersonFields {...this.props} />
            </Grid>
        )
    }

    private handleLogin = async (method: string) => {
        const token = await this.apiBackend.createTransferTokenForPerson(this.props.values.id, method);
        window.open(`${CONFIG.minaSidorUrl}?token=${token.id}&profile=${this.props.values.id}&isadmin=true`);
    };
}

export default EditPersonFormContent;
