import React from "react";
import uuidv4 from "react-uuid";
import { Theme, createStyles, WithStyles, Button, withStyles, Dialog, DialogContent, CircularProgress, DialogTitle, DialogActions } from "@material-ui/core";
import { Formik } from "formik";
import { ApiBackend } from "../../providers/apibackend";
import { ProductCatalogue } from "../../model/ProductCatalogue";
import EditCatalogueFormContent from "./EditCatalogueFormContent";

interface IProps {
    catalogue: ProductCatalogue;
    onSave: (catalogue: ProductCatalogue) => void;
    onAbortEdit: () => void;
}

interface IState {
    loading: boolean;
    catalogue: ProductCatalogue;
    mode: "edit" | "create";
}

class EditCatalogueDialog extends React.Component<WithStyles & IProps, IState> {
    private readonly api: ApiBackend;
    private readonly formId = `edit-catalogue-form-${uuidv4()}`;

    constructor(props: WithStyles & IProps) {
        super(props);

        this.api = new ApiBackend();
        const catalogue = this.props.catalogue;
        const mode = this.props.catalogue.id ? "edit" : "create";

        if (mode === "create") {
            catalogue.id = uuidv4();
        }

        this.state = { catalogue: this.props.catalogue, mode: mode, loading: false };
    }

    render() {
        return <div>
            {this.renderEditPerson()}
        </div>;
    }

    renderEditPerson() {
        const { classes, onAbortEdit, onSave } = this.props;
        const { loading, catalogue, mode } = this.state;

        const title = mode === "edit" ? "Hantera katalog" : "Lägg till katalog";

        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="edit-person-title"
            open={true}
        >
            <DialogTitle id="edit-person-title">{loading ? "Laddar" : title}</DialogTitle>

            {loading &&
                (<>
                    <DialogContent dividers>
                        <CircularProgress color="secondary" />
                    </DialogContent>
                </>)
            }
            {catalogue &&
                <Formik
                    initialValues={catalogue}
                    onReset={(values): void => {
                        this.setState({ catalogue: values });
                        onAbortEdit();
                    }}
                    onSubmit={async (values, { resetForm }) => {
                        const result = await this.save(values);

                        if (result) {
                            resetForm({ values: values });
                            onSave(values);
                        }
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;

                        return (<>
                            <DialogContent dividers>
                                <form id={this.formId} autoComplete="off" onSubmit={handleSubmit}>
                                    <EditCatalogueFormContent {...formik} classes={classes} />
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button form={this.formId} type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">
                                    Spara
                                </Button>
                                <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={handleReset}>
                                    {dirty ? "Avbryt" : "Stäng"}
                                </Button>
                            </DialogActions>
                        </>);
                    }}
                </Formik>
            }

        </Dialog>;
    }

    private save = async (values: ProductCatalogue): Promise<boolean> => {
        return await this.api.addOrUpdateProductCatalogue(values);
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(EditCatalogueDialog);
