export const SexTypes = ["W", "M", "O"] as const;
export type Sex = typeof SexTypes[number];
export const SexTypesLookup: {[key: string]: string} = {
    "W": "Damer",
    "M": "Herrar",
    "O": "Mixed"
}
export const EntryStatusTypes = ["ACTIVE", "CANCELED", "DOUBLE", "REBOOKED", "ILL", "INSURANCE", "SOLD", "REQUEST", "REJECTED", "PENDING", "TIMEOUT", "NOTACTIVE"] as const;
export type EntryStatus = typeof EntryStatusTypes[number];
export const RaceStatusTypes = ["DNS", "DNS*", "DNF", "DNF*", "FNS"] as const;
export type RaceStatus = typeof RaceStatusTypes[number];