/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, withStyles, Button, createStyles, Grid, TableContainer, Paper, Table, TableHead, TableRow, TableBody, Hidden, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, DialogContent, TextField, Typography, Select, MenuItem } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { Product } from '../../model/Product';
import { Event } from '../../model/Event';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { cloneDeep } from 'lodash';
import uuid from 'react-uuid';

interface State {
    loading: boolean;
    products: Product[],
    events: Event[];
    deleteConfirmation: Product;
    showDeleteConfirmation: boolean;
    showArchived: boolean;
    duplicateItem: Product;
    duplicateItemName: string;
    duplicateItemNameEn: string;
    duplicateItemEvent: string;
}

class ManageProducts extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { loading: true, products: null, events: null, deleteConfirmation: null, showDeleteConfirmation: false, showArchived: false, duplicateItem: null, duplicateItemEvent: null as string, duplicateItemName: null as string, duplicateItemNameEn: null as string };
    }

    componentDidMount() {
        this.setState({ loading: true });
        const back = new ApiBackend();

        const work: (Promise<Product[]> | Promise<Event[]>)[] = [
            back.listProducts(),
            back.listEvents()
        ];

        Promise.all(work).then(res => {
            this.setState({ products: res[0] as Product[], events: res[1] as Event[], loading: false });
        });
    }

    private addProduct = () => {
        this.props.history.push("/products/create");
    };

    private editProduct = (product: Product) => () => {
        this.props.history.push("/products/manage/" + product.id);
    };

    private duplicateProduct = (product: Product) => () => {
        this.setState({ duplicateItem: product, duplicateItemName: "Kopia av " + product.name, duplicateItemNameEn: "Copy of " + product.name_en, duplicateItemEvent: product.EventId });
    };

    private deleteProduct = (product: Product) => () => {
        this.setState({ deleteConfirmation: product, showDeleteConfirmation: true });
    };

    private updateProductList = () => {
        this.setState({ loading: true });
        let back = new ApiBackend();
        back.listProducts(this.state.showArchived).then((products) => {
            this.setState({ products: products, loading: false });
        });
    };

    private renderProductsPerEventGroup() {
        if (!this.state || !this.state.products)
            return null;
        let { classes } = this.props as any;
        let ret = [];
        let eg = [];
        eg.push({ id: "", name: "" });
        let pPerEg = new Map<string, Product[]>();
        pPerEg.set("", []);

        for (let i = 0; i < this.state.products.length; i++) {
            let product = this.state.products[i];
            let evId = product.EventId;
            if (evId == null)
                evId = "";
            if (!pPerEg.has(evId)) {
                let events = this.state.events.filter(x => x.id == evId);
                if (events.length > 0) {
                    let event = events[0];
                    eg.push(event);
                    pPerEg.set(evId, [product]);
                }
                else {
                    product.EventId = "";
                    let cur = pPerEg.get("");
                    cur.push(product);
                    pPerEg.set("", cur);
                }
            }
            else {
                let cur = pPerEg.get(evId);
                cur.push(product);
                pPerEg.set(evId, cur);
            }

        }

        eg.sort((x, y) => x.name < y.name ? -1 : 1);
        for (let g = 0; g < eg.length; g++) {
            let event = eg[g];
            let productsInGroup = pPerEg.get(eg[g].id);

            if (!productsInGroup || productsInGroup.length == 0)
                continue;
            productsInGroup.sort((x, u) => x.name < u.name ? -1 : 1);
            if (eg[g].name) {
                ret.push(<TableRow key={g}><StyledTableCell style={{ backgroundColor: '#ddd' }} colSpan={4}>{eg[g].name}</StyledTableCell></TableRow>);
            }
            {
                productsInGroup.map((product, idx) => {
                    ret.push(<TableRow key={g + "_" + idx}>
                        <StyledTableCell style={{ cursor: 'pointer' }} onClick={this.editProduct(product)}>{product.name}</StyledTableCell>
                        <StyledTableCell>{event.name}</StyledTableCell>
                        <StyledTableCell>{product.variants ? product.variants.length : 0}</StyledTableCell>
                        <StyledTableCell align="right">
                            <FileCopyIcon onClick={this.duplicateProduct(product)} className={classes.icon} style={{ cursor: 'pointer' }} />
                            <EditIcon onClick={this.editProduct(product)} className={classes.icon} style={{ cursor: 'pointer' }} />
                            <DeleteIcon onClick={this.deleteProduct(product)} className={classes.icon} style={{ cursor: 'pointer' }} /></StyledTableCell>
                    </TableRow>);
                })
            }
        }

        return ret;
    }

    render() {
        let { classes } = this.props as any;
        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>

                <Card>

                    <CardHeader className={classes.cardHeader} title="Produkter" />
                    <CardContent>
                        {this.state.loading &&
                            <LinearProgress color="secondary" />
                        }

                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={3}>
                                <Button variant="contained" onClick={this.addProduct}>Lägg till produkt</Button>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControlLabel
                                    control={<Checkbox value={this.state.showArchived} onChange={(ev) => { this.setState({ showArchived: ev.target.checked }, () => { this.updateProductList(); }); }} />}
                                    label="Inkludera arkiverade produkter" />
                            </Grid>

                            <Grid item xs={12}>

                                <TableContainer component={Paper}>
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Namn</StyledTableCell>
                                                <StyledTableCell>Evenemang</StyledTableCell>
                                                <StyledTableCell>Antal varianter</StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.renderProductsPerEventGroup()}
                                        </TableBody>
                                    </Table></TableContainer>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
            {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
            {this.state && this.state.duplicateItem && this.renderDuplicateDialog()}
        </Fragment>;
    }

    private renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort produkten?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private renderDuplicateDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Skapa kopia av produkten</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2">Nytt namn</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={this.state.duplicateItemName} onChange={(ev) => { this.setState({ duplicateItemName: ev.target.value }); }} fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2">Nytt namn (engelska)</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={this.state.duplicateItemNameEn} onChange={(ev) => { this.setState({ duplicateItemNameEn: ev.target.value }); }} fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2">Placera i evenemang</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Select value={this.state.duplicateItemEvent} onChange={(ev) => {
                            this.setState(
                                {
                                    duplicateItemEvent: ev.target.value as string
                                });
                        }}
                            fullWidth>
                            {this.state.events.map((x) => {
                                return <MenuItem value={x.id}>{x.name}</MenuItem>
                            })}
                        </Select>
                    </Grid>

                </Grid>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDuplicateCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDuplicateOk} color="primary">
                    Ja, skapa kopia
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    };

    private handleDuplicateCancel = () => {
        this.setState({ duplicateItem: null });
    };

    private handleDeleteOk = () => {
        this.setState({ loading: true });
        var back = new ApiBackend();
        back.deleteProduct(this.state.deleteConfirmation).then((res) => {
            if (res) {
                back.listProducts().then((products) => {
                    this.setState({ deleteConfirmation: null, showDeleteConfirmation: false, products: products, loading: false });
                });
            }
            else {
                alert("Kunde inte ta bort product!");
            }
        })
    };

    private handleDuplicateOk = () => {
        this.setState({ loading: true });
        var back = new ApiBackend();
        let newProduct = new Product();

        newProduct = cloneDeep(this.state.duplicateItem);

        newProduct.EventId = this.state.duplicateItemEvent;
        newProduct.name = this.state.duplicateItemName;
        newProduct.name_en = this.state.duplicateItemNameEn;
        newProduct.IsArchived = false;
        newProduct.id = uuid();
        if (newProduct.variants) {
            for (let i = 0; i < newProduct.variants.length; i++) {
                newProduct.variants[i].Id = uuid();
                if (newProduct.variants[i].priceGroups) {
                    for (let pg = 0; pg < newProduct.variants[i].priceGroups.length; pg++) {
                        newProduct.variants[i].priceGroups[pg].id = uuid();
                    }
                }
                if (newProduct.variants[i].occations) {
                    for (let o = 0; o < newProduct.variants[i].occations.length; o++) {
                        newProduct.variants[i].occations[o].id = uuid();
                    }
                }
            }
        }

        back.createProduct(newProduct).then((res) => {
            this.setState({ loading: false, duplicateItem: null });
            this.props.history.push("/products/manage/" + newProduct.id);
        });
    };
}



const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default withRouter(withStyles(useStyles)(ManageProducts));