/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import React, {Fragment } from 'react'
 import { Card, CardHeader, CardContent, Theme, withStyles, Button, createStyles, Grid, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, DialogContent, TextField, Select, Checkbox } from '@material-ui/core';
 import { withRouter, RouteComponentProps } from 'react-router-dom';
 import { ApiBackend } from '../../providers/apibackend';
 import { SeedingTable, SeedingTableRace } from '../../model/SeedingTable';
 import  uuid from 'react-uuid';
 import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExternalSeedingRaces from './ExternalSeedingRaces';
import ManageSeedingRunSeeding from './ManageSeedingRunSeeding';

 
 interface State {
     loading : boolean;
     seedingTables: SeedingTable[],
     confirmDelete : SeedingTable;
     editItem: SeedingTable;
     availableSeedingRaces: {id : string, name: string}[]
     addRaceSelected: string;
     addRaceExternal: boolean;
     timeEdit:string[];
     timeEditRace: string;
     timeEditGroup: number;
     addRaceYear:number;
 }
 
 class ManageSeedingTables extends React.Component<RouteComponentProps, State> {
     constructor(props : RouteComponentProps)
     {
         super(props);
         this.state = {loading: true, seedingTables : null,confirmDelete: null, editItem: null, availableSeedingRaces:null, addRaceSelected: null, addRaceYear: new Date().getFullYear(),
        timeEdit: null, timeEditGroup: null, timeEditRace: null, addRaceExternal: false};
     }
 
     componentDidMount() {
        this.updateList();       
     }
 
     updateList = () => {
         this.setState({loading:true});
         let back = new ApiBackend();
         back.listSeedingTables().then((tables) => {
             this.setState({ seedingTables: tables, loading:false });
         });

         this.updateSeedingRaces();
         
     }

     updateSeedingRaces = () => {
        let back = new ApiBackend();
        this.setState({availableSeedingRaces : null});
        if (this.state.addRaceExternal)
        {
            back.listExternalSeedingRaces(this.state.addRaceYear).then((races) => 
            {
                this.setState({availableSeedingRaces: races.map(x=>{return {id: x.id, name: x.name + " (" + x.year + ")"}}), addRaceSelected: races[0].id});
            });
        }
        else 
        {
            back.listAvailableSeedingRaces(this.state.addRaceYear).then((races) => 
            {
                this.setState({availableSeedingRaces: races.map(x=>{return {id:x, name:x}}), addRaceSelected: races[0]});
            });
        }
     }

     addTable = () => {
        this.setState({editItem: {
            id: uuid(),
            name:'',
            races: []
        }});
     }
 
     render() {
         let { classes } = this.props as any;

        

         return <Fragment><Grid container className={classes.root} spacing={2}>
             <Grid item xs={12}>
                 
                 <Card>
 
                     <CardHeader className={classes.cardHeader} title="Seedningstabeller" />
                     <CardContent>
                     {this.state.loading &&
                         <LinearProgress color="secondary"/>
                         }
                         
                         <Grid container className={classes.root} spacing={2}>
                         <Grid item xs={3}>
                             <Button variant="contained" onClick={() => {this.addTable();}}>Lägg till tabell</Button>
                         </Grid>
                         <Grid item xs={5}>
                         </Grid>
                        
                         <Grid item xs={12}>
                         
                         <TableContainer component={Paper}>
                             <Table size="small" >
                                 <TableHead>
                                     <TableRow>
                                         <StyledTableCell>Namn</StyledTableCell>
                                         <StyledTableCell style={{width: 70}}></StyledTableCell>
                                     </TableRow>
                                 </TableHead>
                                 <TableBody>
                                     {this.state.seedingTables && this.state.seedingTables.map(table => {
                                        return <TableRow key={table.id}>
                                            <StyledTableCell>{table.name}</StyledTableCell>
                                            <StyledTableCell><EditIcon style={{cursor: 'pointer'}} onClick={() => { this.setState({editItem: table})}}/> 
                                            <DeleteIcon  style={{cursor: 'pointer'}} onClick={() => { this.setState({confirmDelete: table})}}/></StyledTableCell>
                                        </TableRow>
                                     })}
                                 </TableBody>
                             </Table></TableContainer>
                             </Grid>
                             </Grid>
                     </CardContent>
                 </Card>
             </Grid>
         </Grid>

        <ExternalSeedingRaces />
        <ManageSeedingRunSeeding />

         {this.state && this.state.confirmDelete && this.renderConfirmationDialog()}
         {this.state && this.state.editItem &&  this.renderAddFieldDialog()}
         {this.state && this.state.timeEdit &&  this.renderTimeEdit()}
         </Fragment>;
     }

     editTimeVal = (part: number) => (ev : React.ChangeEvent<HTMLInputElement>) => {
            let it = this.state.timeEdit;
            let val = ev.target.value;
            if (part > 0 && +val > 59)
            {
                val = "59";
            }
            if (+val < 0)
            {
                val = "0";
            }
            it[part] = val;
            this.setState({timeEdit: it});
     }

     renderTimeEdit()
     {
        return <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="lg"
        aria-labelledby="confirmation-dialog-title"
        open={true}
    >
        <DialogTitle id="confirmation-dialog-title">Editera tid</DialogTitle>
        <DialogContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>Timmar</Grid>
                    <Grid item xs={12} sm={6}><TextField value={this.state.timeEdit[0]} type="number" inputProps={{min:0}} onChange={this.editTimeVal(0)} /></Grid>
                    <Grid item xs={12} sm={6}>Minuter</Grid>
                    <Grid item xs={12} sm={6}><TextField value={this.state.timeEdit[1]} type="number" inputProps={{min:0, max: 59}} onChange={this.editTimeVal(1)}  /></Grid>
                    <Grid item xs={12} sm={6}>Sekunder</Grid>
                    <Grid item xs={12} sm={6}><TextField value={this.state.timeEdit[2]} type="number" inputProps={{min:0, max: 59}} onChange={this.editTimeVal(2)} /></Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}><Button onClick={() => {
                        let it = this.state.editItem;
                        let ms = (+this.state.timeEdit[0])*3600000+(+this.state.timeEdit[1])*60000+(+this.state.timeEdit[2])*1000;
                        let race = it.races.find(x=>x.id==this.state.timeEditRace);
                        race.startGroupLimits[this.state.timeEditGroup] = ms;
                        this.setState({
                            editItem: it, timeEdit: null, timeEditGroup: null, timeEditRace: null
                        });
                    }}>Spara</Button></Grid>
                </Grid>

        </DialogContent>
        </Dialog>
     }

     
     showEditTime = (race : SeedingTableRace, col : number) => () => {
        let timeMs = race.startGroupLimits && race.startGroupLimits.length > col ? race.startGroupLimits[col]??0 : 0; 
        let hours = Math.floor(timeMs/3600000);
        let minutes = Math.floor((timeMs-hours*3600000)/60000);
        let secs = Math.floor((timeMs-hours*3600000- minutes*60000)/1000);
        this.setState({
            timeEditRace: race.id,
            timeEditGroup: col,
            timeEdit: [""+hours, ""+minutes, ""+secs]
        });
    }

    formatTime(timeMs: number) 
    {
        if (!timeMs)
            return "-";
        let hours = Math.floor(timeMs/3600000);
        let minutes = Math.floor((timeMs-hours*3600000)/60000);
        let secs = Math.floor((timeMs-hours*3600000- minutes*60000)/1000);

        return ""+hours + ":" + (""+minutes).padStart(2,"0") + ":" + (""+secs).padStart(2,"0");
    }

     renderAddFieldDialog() {
        let groups = [] as number[];
        for (let i =0; i < 17; i++)
        {
            groups.push(i);
        }

        let years = [];
        let y = new Date().getFullYear();
        for (let i = y - 10; i<=y; i++)
        {
           years.push(i);
        };

        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Hantera tabell</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={1} sm={1}>Namn</Grid>
                    <Grid item xs={12} sm={6}><TextField value={this.state.editItem.name} onChange={(ev)=>{
                        let it = this.state.editItem;
                        it.name = ev.target.value;
                        this.setState({editItem: it})}}/></Grid>
                    <Grid item xs={12} style={{marginTop:10}}><b>Ingående lopp</b></Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell>
                                    Namn
                                </TableCell>
                                {groups.map(x=>{
                                    return <TableCell>
                                    Grupp {x}
                                </TableCell>
                                })}
                                 <TableCell>
                                    
                                </TableCell>                                
                            </TableRow></TableHead>

                                    <TableBody>
                                    {this.state.editItem && this.state.editItem.races && this.state.editItem.races.map(x=> {
                                        return <TableRow key={x.id}>
                                        <TableCell>
                                            {x.name}
                                        </TableCell>
                                        {groups.map(r=>{
                                            return <TableCell onClick={this.showEditTime(x,r)}>
                                            {x.startGroupLimits && x.startGroupLimits.length > r ? this.formatTime(x.startGroupLimits[r]) : "-"}
                                        </TableCell>
                                        })}   
                                         <TableCell>
                                        <DeleteIcon onClick={() => {
                                            let it = this.state.editItem;
                                            it.races = it.races.filter(f=>f.id != x.id);
                                            this.setState({editItem: it});
                                        }}/>
                                </TableCell>                             
                                    </TableRow>

                                    })}
                                    </TableBody>
                        </Table>
                    
                    

                    </Grid>
                    <Grid item xs={12} style={{marginTop:10}}><b>Lägg till lopp i tabellen</b></Grid>
                    <Grid item xs={2}>År</Grid>
                    <Grid item xs={2}>Externt lopp</Grid>
                    <Grid item xs={4}>Lopp</Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                        <Select native value={this.state.addRaceYear} onChange={(ev) => {this.setState({addRaceYear: +ev.target.value},() => {this.updateSeedingRaces()})}}>
                            {years.map(r => 
                                {
                                    return <option value={r}>{r}</option>;
                                })}
                        </Select>
                    </Grid>
                    <Grid item xs={2}>
                        <Checkbox checked={this.state.addRaceExternal} onChange={(ev) => {this.setState({addRaceExternal: ev.target.checked}, () => {this.updateSeedingRaces()});}}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Select native value={this.state.addRaceSelected} onChange={(ev) => {this.setState({addRaceSelected: ev.target.value as string})}}>
                            {this.state && this.state.availableSeedingRaces && this.state.availableSeedingRaces.map(r => 
                                {
                                    return <option value={r.id}>{r.name}</option>;
                                })}
                        </Select>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" onClick={
                            () => {
                                let it = this.state.editItem;
                                if (!it.races)
                                {
                                    it.races = [];
                                }
                                if (it.races.some(x=>x.raceId == this.state.addRaceSelected))
                                {
                                    alert("Loppet finns redan i listan!");
                                }
                                else 
                                {
                                    if (this.state.addRaceExternal)
                                    {
                                        const race = this.state.availableSeedingRaces.find(x=>x.id==this.state.addRaceSelected);
                                        it.races.push({
                                            id: uuid(),
                                            isExternal: true,
                                            raceId: this.state.addRaceSelected,
                                            name: race.name,
                                            startGroupLimits: Array(17)
                                        });
                                    }
                                    else 
                                    {
                                        it.races.push({
                                            id: uuid(),
                                            isExternal: false,
                                            raceId: this.state.addRaceSelected,
                                            name: this.state.addRaceSelected,
                                            startGroupLimits: Array(17)
                                        });
                                    }
                                    this.setState({editItem: it});
                                }
                            }
                        }>Lägg till</Button>


                    </Grid>
                </Grid>


            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={() => { this.setState({editItem: null})}} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={() => {
                    let back = new ApiBackend();
                    this.setState({loading:true});
                    back.addOrUpdateSeedingTable(this.state.editItem).then((res) => {
                        this.setState({editItem: null},() => {
                            this.updateList();
                        });
                    })
                }} color="primary">
                   Spara 
          </Button>
            </DialogActions>
        </Dialog>;
    }
 
     renderConfirmationDialog() {
         return <Dialog
             disableBackdropClick
             disableEscapeKeyDown
             maxWidth="xs"
             aria-labelledby="confirmation-dialog-title"
             open={true}
         >
             <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort tabellen?</DialogTitle>
             <DialogActions>
                 <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                     Avbryt
           </Button>
                 <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                     Ja, ta bort
           </Button>
             </DialogActions>
         </Dialog>;
     }
 
     handleDeleteCancel = () => {
         this.setState({ confirmDelete: null});
     }
 
     handleDeleteOk = () => {
         this.setState({loading:true});         
         let back=new ApiBackend();
         back.deleteSeedingTable(this.state.confirmDelete.id).then((res) => {
            this.setState({
                confirmDelete: null,
            },() => {
                this.updateList()
            });
         })
     }
 }
  
 const useStyles = ({ palette, spacing }: Theme) => createStyles({
     cardHeader: {
         background: palette.secondary.main,
         color: palette.secondary.contrastText,
         padding: 3
     },
     photo: {
         height: '30px',
         verticalAlign: 'middle',
         borderRadius: '10px'
     },
     root: {
 
     },
     form: {
         '& > *': {
             margin: spacing(1),
             width: '25ch',
         },
         '& label.Mui-focused': {
             color: palette.secondary.main,
         },
         '& .MuiInput-underline:after': {
             borderBottomColor: palette.secondary.main,
         },
     }
 }
 );
 
 const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
     head: {
         background: palette.primary.main,
         color: palette.primary.contrastText,
     }
 });
 
 const StyledTableCell = withStyles(tableHeadStyles)(TableCell);
 
 export default withRouter(withStyles(useStyles)(ManageSeedingTables));