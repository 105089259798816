import React from 'react';
import uuid from 'react-uuid';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Paper, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Event } from '../../../model/Event';
import { ApiBackend } from '../../../providers/apibackend';
import { Product } from '../../../model/Product';
import { Formik, FormikProps } from 'formik';
import FormTextField from '../../Common/FormTextField';
import FormDateField from '../../Common/FormDateField';
import FormSelectField from '../../Common/FormSelectField';
import TransferList from '../../Common/TransferList';
import { DATE_FORMAT, DATE_TIME_FORMAT, vasaloppetMoment } from '../../../utilities/date';

interface IProps {
    event: Event;
    cancel: () => void;
    onSave: () => void;
}

interface IState {
    loading: boolean;
    model: IModel;
    step: Step;
}

interface IModel {
    event: {
        id: string;
        name: string;
        startDate: string;
        endDate: string;
    },
    products: IProductModel[]
}

interface IProductModel {
    id: string;
    name: string;
    date: string; // Första startgruppens datum -> ändring kopiera över till samtliga startgrupper, men behåll gamla tidsangivelsen
    variants: IProductVariantModel[];
}

interface IProductVariantModel {
    id: string;
    name: string;
    hasEventKey: boolean;
    eventKey: string;
    priceGroups: IProductPriceGroupModel[];
    occations?: IProductOccationModel[];
}

interface IProductOccationModel {
    id: string;
    time: string;
}

interface IProductPriceGroupModel {
    id: string;
    name: string;
    validFrom: string;
    validTo: string;
}

type Step = "1" | "2" | "3";

class CopyEventDialog extends React.Component<IProps & WithStyles> {
    state: IState;

    private formikHandleReset: (e?: React.SyntheticEvent<any>) => void; // Will be "bound" when we have access to formik props
    private events: Event[];
    private products: Product[];
    private readonly api: ApiBackend;
    private readonly formId = `copy-event-form-${uuid()}`;

    constructor(props: IProps & WithStyles) {
        super(props);

        this.state = { loading: false, model: undefined, step: "1" };
        this.api = new ApiBackend();
    }

    async componentDidMount(): Promise<void> {
        this.setState({ loading: true });
        const events = await this.api.listEvents();
        this.events = events.sort((a, b) => a.name.localeCompare(b.name, "sv"));
        this.products = await this.api.getProductsForEvent(this.props.event.id);
        this.initModel();
    }

    render() {
        const { classes, event } = this.props;
        const { loading, model, step } = this.state;

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="copy-event-title"
                open={true}
            >
                <DialogTitle id="edit-event-title">
                    {
                        (<>
                            Kopiera Event
                            <IconButton color="default" title="Återställ"
                                style={{ position: 'absolute', right: 8, top: 8 }}
                            >
                                <RefreshIcon
                                    onClick={() => {
                                        this.formikHandleReset();
                                    }}
                                />
                            </IconButton>
                        </>)
                    }
                </DialogTitle>

                {loading &&
                    (<>
                        <DialogContent dividers>
                            <CircularProgress color='secondary' />
                        </DialogContent>
                    </>)
                }
                {model &&
                    <Formik
                        initialValues={model}
                        onReset={(values): void => {
                            this.setState({ step: "1" });
                        }}
                        onSubmit={async (values) => {
                            await this.handleSubmit(values);
                        }}
                    >
                        {formik => {
                            const { isValid, dirty, handleSubmit, handleReset, isSubmitting, values, setFieldValue } = formik;
                            const isSaveDisabled = !dirty || !isValid || isSubmitting || step !== "3";
                            const targetEventIsSelected = !!values.event.id;

                            if (!this.formikHandleReset) {
                                this.formikHandleReset = handleReset;
                            }

                            return (<>
                                <DialogContent dividers>
                                    <form id={this.formId} autoComplete="off" onSubmit={handleSubmit}>
                                        <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                            <Grid item xs={6}>
                                                <FormSelectField {...formik}
                                                    propName="event.id"
                                                    label="Välj ett evenemang att kopiera till eller lämna tomt för att skapa ett nytt evenemang"
                                                    valueGetter={() => values.event.id ?? ""}
                                                    onChange={(handler) => (e: any) => {
                                                        const evt = this.events.find(x => x.id === e.target.value);
                                                        setFieldValue("event", evt);
                                                    }}
                                                >
                                                    {this.events.map((x) => {
                                                        return <MenuItem value={x.id}>{x.name}</MenuItem>;
                                                    })}
                                                </FormSelectField>
                                            </Grid>
                                            <Grid item xs={6} />
                                            <Grid item xs={6}>
                                                <FormTextField {...formik}
                                                    disabled={targetEventIsSelected}
                                                    label="Arrangemangsnamn"
                                                    propName="event.name"
                                                    valueGetter={() => values.event.name}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormDateField {...formik}
                                                    disabled={targetEventIsSelected || step !== "1"}
                                                    propName="event.startDate"
                                                    label="Startdatum"
                                                    inputProps={{ min: event.startDate, max: "9999-12-31" }}
                                                    valueFormatter={() => values.event.startDate ?? ''}
                                                    onChange={() => (e: any) => {
                                                        let nexValue = e.target.value;
                                                        if (!nexValue) {
                                                            nexValue = null;
                                                        }
                                                        formik.setFieldValue("event.startDate", nexValue);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormDateField {...formik}
                                                    disabled={targetEventIsSelected || step !== "1"}
                                                    propName="event.endDate"
                                                    label="Slutdatum"
                                                    inputProps={{ min: event.endDate, max: "9999-12-31" }}
                                                    valueFormatter={() => values.event.endDate ?? ''}
                                                    onChange={() => (e: any) => {
                                                        let nexValue = e.target.value;
                                                        if (!nexValue) {
                                                            nexValue = null;
                                                        }
                                                        formik.setFieldValue("event.endDate", nexValue);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {step === "2" && this.renderProductSelection(formik, values)}
                                        {step === "3" && this.renderProducts(formik, values)}
                                        {step !== "3" && (
                                            <Box
                                                m={1}
                                                display="flex"
                                                justifyContent="flex-end"
                                                alignItems="flex-end"
                                            >
                                                <Button color="secondary" variant="contained" onClick={() => this.handleNext(values)}>Nästa</Button>
                                            </Box>
                                        )}
                                    </form>
                                </DialogContent>
                                <DialogActions>
                                    <Button form={this.formId} type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">
                                        Kopiera
                                    </Button>
                                    <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={this.handleAbort}>
                                        {dirty ? "Avbryt" : "Stäng"}
                                    </Button>
                                </DialogActions>
                            </>);
                        }}
                    </Formik>
                }
            </Dialog>
        );
    }

    private renderProductSelection(formik: FormikProps<IModel>, values: IModel) {
        const { classes } = this.props;

        return (
            <Card>
                <CardHeader titleTypographyProps={{ variant: "h6" }} title="Välj produkter" />
                <CardContent>
                    <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                        {this.products && (
                            <Grid item xs={12}>
                                <TransferList
                                    classes={classes}
                                    items={this.products}
                                    choicesTitle={"Välj produkter"}
                                    choosenTitle={"Valda produkter"}
                                    valueFormatter={(product: Product) => product.name}
                                    onChange={(items) => {
                                        this.handleProductSelectionChanged(items, formik)
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    private renderProducts(formik: FormikProps<IModel>, values: IModel) {
        const { classes } = this.props;
        const cardStyle = { width: '100%', marginTop: 20, padding: 10 };
        const paperStyle = { width: '100%', marginTop: 10, padding: 10 };

        return (<>
            {
                values.products.map((p, pIdx) => {
                    return (
                        <Card style={cardStyle} elevation={1}>
                            <CardHeader titleTypographyProps={{ variant: "h6" }} title={p.name} />
                            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                <Grid item xs={6}>
                                    <FormTextField {...formik}
                                        label="Namn"
                                        propName={`products[${pIdx}].name`}
                                        valueGetter={() => p.name}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormDateField {...formik}
                                        propName={`products[${pIdx}].date`}
                                        label="Datum"
                                        inputProps={{ max: "9999-12-31" }}
                                        valueFormatter={() => p.date ?? ''}
                                        onChange={() => (e: any) => {
                                            let nexValue = e.target.value;
                                            if (!nexValue) {
                                                nexValue = null;
                                            }
                                            formik.setFieldValue(`products[${pIdx}].date`, nexValue);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption">Varianter</Typography>
                                    {p.variants.map((v, vIdx) => (
                                        <Paper style={paperStyle} elevation={2}>
                                            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                                <Grid item xs={6}>
                                                    <FormTextField {...formik}
                                                        label="Namn"
                                                        propName={`products[${pIdx}].variants[${vIdx}].name`}
                                                        valueGetter={() => v.name}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormTextField {...formik}
                                                        label="EventKey"
                                                        propName={`products[${pIdx}].variants[${vIdx}].eventKey`}
                                                        valueGetter={() => v.eventKey}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption">Prisgrupper</Typography>
                                                    {v.priceGroups.map((pg, pgIdx) => (
                                                        <Paper style={paperStyle} elevation={3}>
                                                            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                                                <Grid item xs={4}>
                                                                    <FormTextField {...formik}
                                                                        label="Namn"
                                                                        propName={`products[${pIdx}].variants[${vIdx}].priceGroups[${pgIdx}].name`}
                                                                        valueGetter={() => pg.name}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <FormDateField {...formik}
                                                                        propName={`products[${pIdx}].variants[${vIdx}].priceGroups[${pgIdx}].validFrom`}
                                                                        label="Öppen från"
                                                                        inputProps={{ max: "9999-12-31" }}
                                                                        valueFormatter={() => pg.validFrom ?? ''}
                                                                        onChange={() => (e: any) => {
                                                                            let nexValue = e.target.value;
                                                                            if (!nexValue) {
                                                                                nexValue = null;
                                                                            }
                                                                            formik.setFieldValue(`products[${pIdx}].variants[${vIdx}].priceGroups[${pgIdx}].validFrom`, nexValue);
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <FormDateField {...formik}
                                                                        propName={`products[${pIdx}].variants[${vIdx}].priceGroups[${pgIdx}].validTo`}
                                                                        label="Öppen till"
                                                                        inputProps={{ max: "9999-12-31" }}
                                                                        valueFormatter={() => pg.validTo ?? ''}
                                                                        onChange={() => (e: any) => {
                                                                            let nexValue = e.target.value;
                                                                            if (!nexValue) {
                                                                                nexValue = null;
                                                                            }
                                                                            formik.setFieldValue(`products[${pIdx}].variants[${vIdx}].priceGroups[${pgIdx}].validTo`, nexValue);
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    ))}
                                </Grid>
                            </Grid>
                        </Card>
                    )
                })
            }
        </>);
    }

    private handleNext = (formikValues: IModel) => {
        const { step } = this.state;

        switch (step) {
            case "1":
                this.setState({ step: "2" });
                break;
            case "2":
                this.setupForStep3(formikValues);
                break;
        }
    }

    private setupForStep3 = (formikValues: IModel) => {
        const { event } = this.props;
        const sourceStartDate = vasaloppetMoment(event.startDate);
        const currentStartDate = vasaloppetMoment(formikValues.event.startDate);
        const dayDiffEvent = currentStartDate.diff(sourceStartDate, "days");

        formikValues.products.forEach((p) => {
            const product = this.products.find(x => x.id === p.id);
            const firstStartGroup = product.startGroups && product.startGroups.length > 0 ? product.startGroups[0] : null;
            if (firstStartGroup) {
                const sourceSgStart = vasaloppetMoment(firstStartGroup.startTime);
                const startTimeDiff = sourceSgStart.diff(sourceStartDate, "days");
                const targetSgStart = vasaloppetMoment(formikValues.event.startDate).add(startTimeDiff, "days");
                p.date = targetSgStart.format(DATE_FORMAT);

            } else {
                p.date = formikValues.event.startDate; // defaults to event date
            }

            p.variants.forEach((v) => {
                const variant = product.variants.find(x => x.Id === v.id);
                v.priceGroups.forEach((pg) => {
                    const sourcePriceGroup = variant.priceGroups.find(x => x.id === pg.id);
                    if (sourcePriceGroup.validFrom) {
                        const sourceValidFrom = vasaloppetMoment(sourcePriceGroup.validFrom);
                        sourceValidFrom.add(dayDiffEvent, "days");
                        pg.validFrom = sourceValidFrom.isValid() ? sourceValidFrom.format(DATE_TIME_FORMAT) : null;
                    }

                    if (sourcePriceGroup.validTo) {
                        const sourceValidTo = vasaloppetMoment(sourcePriceGroup.validTo);
                        sourceValidTo.add(dayDiffEvent, "days");
                        pg.validTo = sourceValidTo.isValid() ? sourceValidTo.format(DATE_TIME_FORMAT) : null;
                    }
                });

                if (v.occations) {
                    v.occations.forEach((o) => {
                        const sourceOccation = variant.occations.find(x => x.id === o.id);
                        if (sourceOccation.time) {
                            const sourceTime = vasaloppetMoment(sourceOccation.time);
                            sourceTime.add(dayDiffEvent, "days");
                            o.time = sourceTime.isValid() ? sourceTime.format(DATE_FORMAT) : null;
                        }
                    });
                }
            });
        });

        this.setState({ step: "3" });
    };

    private handleAbort = () => {
        const { cancel } = this.props;
        cancel();
    };

    private handleSubmit = async (formikValues: IModel) => {
        const { onSave } = this.props;
        const mergedValues = this.getMergedValues(formikValues);
        const toSave = { ...mergedValues.event, products: mergedValues.products };

        await this.api.createOrUpdateEvent(toSave as any);
        onSave();
    };

    private getMergedValues(values: IModel): { event: Event; products: Product[] } {
        const mappedEvent = {
            id: values.event.id ?? uuid(),
            name: values.event.name,
            startDate: values.event.startDate,
            endDate: values.event.endDate
        };

        if (!this.products || this.products.length === 0) {
            return {
                event: mappedEvent,
                products: null
            }
        }

        const clonedProducts: Product[] = JSON.parse(JSON.stringify(this.products));
        const mappedProducts = clonedProducts.filter(p => values.products.some(x => x.id === p.id));

        mappedProducts.forEach((p) => {
            const pVal = values.products.find(x => x.id === p.id);
            p.id = uuid();
            p.name = pVal.name;
            p.EventId = mappedEvent.id;
            p.Event = null;
            p.active = false;
            p.IsArchived = false;
            const productStartDate = pVal.date.split("T")[0];

            if (p.variants) {
                p.variants.forEach((v) => {
                    const vVal = pVal.variants.find(x => x.id === v.Id);
                    v.Id = uuid();
                    v.Name = vVal.name;

                    if (v.productVariantRaceData) {
                        v.productVariantRaceData.eventKey = vVal.eventKey;
                    }

                    v.priceGroups.forEach((pg) => {
                        const pgVal = vVal.priceGroups.find(x => x.id === pg.id);
                        pg.id = uuid();
                        pg.Name = pgVal.name;
                        pg.validFrom = !!pgVal.validFrom ? pgVal.validFrom : null;
                        pg.validTo = !!pgVal.validTo ? pgVal.validTo : null;
                    });

                    if (v.ageCategories) {
                        v.ageCategories.forEach(ac => {
                            ac.id = uuid();
                        });
                    }

                    if (v.occations) {
                        v.occations.forEach(o => {
                            const oVal = vVal.occations.find(x => x.id === o.id);
                            o.id = uuid();
                            o.time = oVal.time as any;
                        });
                    }
                });
            }

            if (p.startGroups) {
                p.startGroups.forEach((sg) => {
                    sg.id = uuid();
                    const startTime = (sg.startTime as unknown as string).split("T")[1];
                    sg.startTime = `${productStartDate}T${startTime}` as any;
                });
            }

            if (p.departures) {
                p.departures.forEach(d => {
                    d.id = uuid();
                });
            }
        });

        return {
            event: mappedEvent,
            products: mappedProducts
        }
    }

    private handleProductSelectionChanged = (items: Product[], formik: FormikProps<IModel>) => {
        const selected = this.products.filter(p => items.some(x => x.id === p.id));

        const products = selected.map(p => {
            const variants: IProductVariantModel[] = p.variants.map(v => {

                const priceGroups: IProductPriceGroupModel[] = v.priceGroups.map(pg => {
                    return {
                        id: pg.id,
                        name: pg.Name,
                        validFrom: pg.validFrom ? pg.validFrom.split("T")[0] : "",
                        validTo: pg.validTo ? pg.validTo.split("T")[0] : ""
                    }
                });

                const occations: IProductOccationModel[] = v.occations ? v.occations.map(o => {
                    return {
                        id: o.id,
                        time: o.time as any
                    }
                }) : null;

                return {
                    id: v.Id,
                    name: v.Name,
                    hasEventKey: !!v.productVariantRaceData?.eventKey,
                    eventKey: v.productVariantRaceData?.eventKey,
                    priceGroups: priceGroups,
                    occations: occations
                }
            });

            return {
                id: p.id,
                name: p.name,
                date: p.startGroups && p.startGroups.length > 0 ? (p.startGroups[0].startTime as unknown as string).split("T")[0] : "",
                variants: variants
            }
        });

        formik.setFieldValue("products", products);
    };

    private initModel(): void {
        let products: IProductModel[] = [];

        const model: IModel = {
            event: {
                id: undefined,
                name: this.props.event.name,
                startDate: this.props.event.startDate,
                endDate: this.props.event.endDate
            },
            products: products
        };

        this.setState({ model: model, loading: false });
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    list: {
        minHeight: '200px'
    },
    paper: {
        height: "100%",
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(CopyEventDialog);
