/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';

import { Product } from '../../model/Product';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Link, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddBox';
import CardHeader from '@material-ui/core/CardHeader';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { Reservation } from '../../model/Reservation';
import ReservationForm from './ReservationForm';
import { vasaloppetDateTimeGetterFormatter } from '../../utilities/date';

interface State {
    loading: boolean;
    races: Product[];
    reservations: Reservation[];
    editReservation: Reservation;
    deleteReservation: Reservation;
}

interface RouteParams {
    id: string;
}

class Reservations extends React.Component<RouteComponentProps<RouteParams> & WithStyles, State> {
    static contextType = FirebaseContext;
    constructor(props: RouteComponentProps<RouteParams> & WithStyles) {
        super(props);
        this.state = { loading: true, races: null, reservations: null, editReservation: null, deleteReservation: null };
    }

    componentDidMount() {
        this.refresh();
    }


    refresh = () => {
        this.setState({ loading: true });
        let back = new ApiBackend();
        let p1 = back.listProducts().then((products) => {
            this.setState({ races: products.filter(x => x.type == "race" && !x.IsArchived) });
        });
        let p2 = back.listReservations().then((reservations) => {
            this.setState({ reservations: reservations });
        });

        Promise.all([p1, p2]).then(() => {
            this.setState({ loading: false });
        })
    }

    saveReservation = (item: Reservation) => {
        let back = new ApiBackend();
        this.setState({ loading: true });
        back.updateReservation(item).then(() => {
            back.listReservations().then((reservations) => {
                this.setState({ reservations: reservations, loading: false, editReservation: null });
            });
        }
        );
    }

    deleteReservation = () => {
        let back = new ApiBackend();
        this.setState({ loading: true });
        back.deleteReservation(this.state.deleteReservation).then(() => {
            back.listReservations().then((reservations) => {
                this.setState({ reservations: reservations, loading: false, deleteReservation: null });
            });
        }
        );
    }


    render() {
        let { classes } = this.props;
        return <Card><CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Reservationer</Typography>

            <AddIcon style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: 10 }}
                onClick={() => {
                    let res = { name: '', description: '', responsible: '' } as Reservation;
                    this.setState({ editReservation: res });
                }} /></Fragment>} />
            <CardContent>
                {this.state && this.state.loading &&
                    <LinearProgress variant="indeterminate" color="secondary" />
                }
                <Grid container spacing={2}>
                    <Grid className={classes.gridHeader} item xs={2}>
                        <b>Namn</b>
                    </Grid>
                    <Grid className={classes.gridHeader} item xs={2}>
                        <b>Skapad</b>
                    </Grid>
                    <Grid className={classes.gridHeader} item xs={2}>
                        <b>Uppdaterad</b>
                    </Grid>
                    <Grid className={classes.gridHeader} item xs={2}>
                        <b>Ansvarig</b>
                    </Grid>
                    <Grid className={classes.gridHeader} item xs={2}>
                        <b>Beskrivning</b>
                    </Grid>
                    <Grid className={classes.gridHeader} item xs={2}>
                    </Grid>
                    {this.state.reservations && this.state.reservations.map((reservation) => {
                        return <Fragment>
                            <Grid item xs={2}>
                                <Link color="inherit" href="#" onClick={(event: React.SyntheticEvent) => {
                                    this.setState({ editReservation: reservation });
                                    event.preventDefault();
                                }}>{reservation.name}</Link>
                            </Grid>
                            <Grid item xs={2}>
                                {vasaloppetDateTimeGetterFormatter(reservation.created)}
                            </Grid>
                            <Grid item xs={2}>
                                {vasaloppetDateTimeGetterFormatter(reservation.updated)}
                            </Grid>
                            <Grid item xs={2}>
                                {reservation.responsible}
                            </Grid>
                            <Grid item xs={2}>
                                {reservation.description}
                            </Grid>
                            <Grid item xs={2}>
                                <EditIcon style={{ cursor: 'pointer' }} onClick={() => { this.setState({ editReservation: reservation }) }} />
                                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => { this.setState({ deleteReservation: reservation }) }} />
                            </Grid>
                        </Fragment>;
                    })}
                </Grid>
                {this.state && this.state.editReservation &&
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        maxWidth="lg"
                        aria-labelledby="confirmation-dialog-title"
                        open={true}
                    >
                        <DialogContent dividers>
                            <ReservationForm products={this.state.races} reservation={this.state.editReservation} save={this.saveReservation} abortEdit={() => {
                                this.setState({ editReservation: null });
                            }} />
                        </DialogContent>
                    </Dialog>
                }
                {this.state && this.state.deleteReservation &&
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        maxWidth="xs"
                        aria-labelledby="confirmation-dialog-title"
                        open={true}
                    >
                        <DialogTitle id="confirmation-dialog-title">Ta bort {this.state.deleteReservation.name}?</DialogTitle>
                        <DialogContent>
                            Är du säker på att du vill ta bort reservationen?
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.deleteReservation}>Ja, ta bort</Button><Button onClick={() => { this.setState({ deleteReservation: null }) }}>Nej, avbryt</Button>
                        </DialogActions>
                    </Dialog>
                }
            </CardContent>
        </Card>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    gridHeader: {
        background: '#eee',
        padding: 10,
        fontStyle: 'bold'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);



export default withStyles(useStyles)(withRouter(Reservations));
